import Vue from "vue";
import Vuetify from "vuetify/lib";
// Translation provided by Vuetify (javascript)
import he from "vuetify/es5/locale/he";

Vue.use(Vuetify);

const opts = {
    icons: {
        values: {
            customPdf: {
                component: 'img',
                props: {
                    src: require('@/assets/icons/pdf-icon.png')
                }
            }
        }
    },
    rtl: true,
    lang: {
        locales: { he },
        current: "he"
    },
    theme: {
        themes: {
            light: {
                primary: "#1847e8",
                primarydark: "#0D0DC8",
                primaryanagous: "#17D3E8",
                // 22263b, f9fafe,
                secondary: "#f7f8f9",
                accent: "#8c9eff",
                error: "#b71c1c",
                info: "#0099ad",
                grey: "#efefef",
                pink: "#fff0e8",
                black: "#000000"
            }
        }
    }
};

export default new Vuetify(opts);
<template>
  <LawyerLayout>
    <v-container fill-height :style="'display: ' + showMainContainer">
      <v-row align="center" justify="center">
        <v-col xs="10" md="8" xl="6">
          <v-card elevation="3" outlined light class="mt-auto">
            <v-card-title class="primary white--text py-2">
              <v-layout justify-center>
                <v-icon dark size="20px" class="ml-3"> mdi-lock</v-icon>
                <h4>הרשמה ראשונית למערכת</h4>
              </v-layout>
            </v-card-title>
            <v-divider />
            <v-card-text align="center">
              <v-form
                class="mr-3 ml-3"
                v-model="valid"
                @submit.prevent="onSubmit"
              >
                <v-row>
                  <v-col md="6" offset-md="3">
                    <!-- <v-alert dense elevation="5" outlined type="success"
                      >לנרשמים חדשים - 30 יום נסיון חינם!</v-alert
                    > -->
                  </v-col>
                </v-row>
                <v-row>
                  <div class="text-h6 font-weight-bold">
                    פרטים אישיים
                  </div>
                </v-row>
                <v-row>
                  <div class="text-subtitle-2">
                    חשוב לוודא ששמך כתוב ומאוית בצורה נכונה. זה השם שיופיע
                    ללקוחתיך בפניות אליהם
                  </div>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="email"
                      label="כתובת מייל"
                      outlined
                      dense
                      :rules="emailRules"
                      hint="וודא שכתובת המייל שלך כתובה בצורה נכונה. לכתובת מייל זו ישלחו מיילים המיועדים אליך מהמערכת"
                      persistent-hint
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col sm="6" cols="12">
                    <v-text-field
                      v-model="first_name"
                      label="שם פרטי"
                      outlined
                      :rules="requiredRules"
                      dense
                      persistent-hint
                    ></v-text-field>
                  </v-col>
                  <v-col sm="6" cols="12">
                    <v-text-field
                      v-model="last_name"
                      label="שם משפחה"
                      outlined
                      :rules="requiredRules"
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <div class="text-h6 font-weight-bold">
                    בחירת סיסמה
                  </div>
                </v-row>
                <v-row>
                  <div class="text-subtitle-2 text-right">
                    הסיסמה צריכה להיות שילוב של אותיות ומספרים באורך של 8 תווים
                    לפחות. חשוב להימנע מבחירת סיסמאות גנריות או סיסמה הזהה לשם
                    המשתמש שלך
                  </div>
                </v-row>

                <v-row>
                  <v-col sm="6" cols="12">
                    <v-text-field
                      v-model="password"
                      label="סיסמה"
                      :type="showPassword ? 'text' : 'password'"
                      outlined
                      clearable
                      dense
                      :rules="passwordRules"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showPassword = !showPassword"
                    ></v-text-field>
                  </v-col>
                  <v-col sm="6" cols="12">
                    <v-text-field
                      v-model="confirmPassword"
                      label="ווידוא סיסמה"
                      :type="showconfirmPassword ? 'text' : 'password'"
                      outlined
                      clearable
                      dense
                      :rules="confirmPasswordRules"
                      :append-icon="
                        showconfirmPassword ? 'mdi-eye' : 'mdi-eye-off'
                      "
                      @click:append="showconfirmPassword = !showconfirmPassword"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col align="center">
                    <vue-recaptcha
                      ref="recaptcha"
                      @verify="onVerify"
                      @expired="onExpired"
                      size="invisible"
                      :sitekey="sitekey"
                      :loadRecaptchaScript="true"
                    />
                    <v-btn
                      type="submit"
                      :loading="loading"
                      :disabled="!valid"
                      color="primary"
                      @click="loader = 'loading'"
                    >
                      <v-icon class="mx-2" right>mdi-lock</v-icon>
                      הירשם
                    </v-btn>
                    <v-alert
                      class="mt-5"
                      v-for="(item, index) in error"
                      :key="index"
                      dense
                      outlined
                      type="warning"
                    >
                      <span v-for="i in item" :key="i">
                        {{ i }}
                      </span>
                    </v-alert>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- Snack bar notification bar -->
      <v-snackbar
        v-model="snackbarShow"
        timeout="2000"
        outlined
        color="primary"
      >
        <v-icon small class="mr-2" color="primary">
          mdi-check-circle
        </v-icon>
        פרטי החשבון עודכנו בהצלחה
        <template v-slot:action="{ attrs }">
          <v-btn outlined text v-bind="attrs" @click="snackbarShow = false">
            סגור
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
    <v-dialog v-model="dialog" persistent max-width="350">
      <v-card elevation="2" oulined>
        <v-card-title class="headline justify-center">
          <b>בדוק את תיבת הדו״אל שלך</b>
        </v-card-title>
        <v-img
          class="mb-3 mt-3"
          contain
          :src="require(`@/assets/icons/CheckEmail.jpg`)"
          transition="scale-transition"
          height="150"
        />
        <v-card-text class="text-center">
          בכדי לסיים ההרשמה עליך להפעיל את החשבון באמצעות לחיצה על הלינק שנשלח
          למייל שהזנת
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="primary" outlined to="/Login">
            <v-icon dark size="20px" class="ml-3 mb-3"> mdi-lock</v-icon>
            כניסה למערכת
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Error msg container -->
    <v-container
      fill-height
      :style="'display: ' + showErrorMsg"
      id="errorMessegeContainer"
    >
      <v-row align="center" justify="center">
        <v-col xs="10" md="6" xl="4">
          <v-card elevation="3" outlined light class="mt-auto">
            <v-card-title class="primary white--text py-2">
              <v-layout justify-center>
                <v-icon dark size="20px" class="ml-3">
                  mdi-shield-alert-outline</v-icon
                >
                <h4>{{ msg["msg_header"] }}</h4>
              </v-layout>
            </v-card-title>
            <v-divider />
            <v-card-text align="center">
              <v-col cols="10" class="text-body-1" style="color:black">
                {{ msg["msg_text"] }}
              </v-col>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </LawyerLayout>
</template>

<script>
import ApiService from "@/services/ApiService.js";
import LawyerLayout from "@/components/LawyerLayout.vue";
import VueRecaptcha from "vue-recaptcha";

export default {
  name: "Register-page",
  components: {
    LawyerLayout,
    VueRecaptcha
  },
  data() {
    return {
      e1: 1,
      valid: false,
      loading: false,
      email: "",
      first_name: "",
      last_name: "",
      password: "",
      confirmPassword: "",
      error: null,
      showPassword: false,
      showconfirmPassword: false,
      snackbarShow: false,
      dialog: false,
      showErrorMsg: "none",
      showMainContainer: "unset",
      sitekey: process.env.VUE_APP_RECAPTCHA_SITEKEY,
      offers: [
        {
          title: "רכישת מנוי פרימיום",
          msg:
            "מודול ״הכר את הלקוח״ כלול במנוי הפרימיום של קליגל. בנוסף, ברכישת מנוי פרימיום תוכלו להנות מכל התכונות המתקדמות של קליגל.",
          next_to_stepper: false,
          href: "https://cligal.com/%d7%9e%d7%97%d7%99%d7%a8%d7%99%d7%9d/"
        },
        {
          title: "גרסת נסיון",
          msg:
            "עדיין לא מוכן להתחייב? זה בסדר. תוכל להתנסות במודול ״הכר את הלקוח״ במסגרת גרסת הנסיון שלנו שתהיה פתוחה ל-30 יום ללא השארת פרטי כרטיס אשראי",
          next_to_stepper: true
        }
      ],
      msg: {},
      requiredRules: [value => !!value || "חובה להזין ערך"],
      emailRules: [
        v => !!v || "אנא הזן כתובת מייל",
        v =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "כתובת מייל לא חוקית"
      ],
      passwordRules: [
        value => !!value || "אנא  הזן סיסמה חדשה",
        value =>
          (value && value.length >= 8) ||
          "הסיסמה קצרה מדי (נדרש לפחות 8 תווים)",
        value => /(?!^\d+$)^.+$/.test(value) || "הסיסמה מכילה רק מספרים"
      ],
      confirmPasswordRules: [
        value => !!value || "אנא הזן שוב את הסיסמה החדשה",
        value => value === this.password || "הסיסמאות שהזנת אינן זהות"
      ]
    };
  },
  methods: {
    register(recaptchaToken) {
      this.loading = true;
      ApiService.register({
        email: this.email,
        password: this.password,
        first_name: this.first_name,
        last_name: this.last_name,
        recaptchaToken: recaptchaToken
      })
        .then(response => {
          this.loading = false;
          console.log(response);
          this.dialog = true;
        })
        .catch(err => {
          this.loading = false;
          if (err.response.status === 400) {
            this.error = err.response.data;
          }
        });
    },
    // handle the recaptcha logic
    onSubmit: function() {
      this.$refs.recaptcha.execute();
    },
    onVerify: function(recaptchaToken) {
      this.$refs.recaptcha.reset();
      this.register(recaptchaToken);
    },
    onExpired: function() {
      this.$refs.recaptcha.reset();
    },
    resetRecaptcha() {
      this.$refs.recaptcha.reset(); // Direct call reset method
    }
  }
};
</script>

<style lang="scss" scoped></style>

<template>
    <div>
        <p v-if="token">Token received: {{ token }}</p>
        <p v-else>Loading...</p>
        <p v-if="error">Error: {{ error }}</p>
    </div>
</template>

<script>

//import ApiService from "@/services/ApiService.js";

    export default {
        name: "CaptureToken",
        data() {
            return {
                token: null,
                error: null
            };
        },
        mounted() {
            // Capture the token from the URL when the component is mounted
            this.token = this.$route.query.token;

            if (!this.token) {
                this.error = "No token found in the URL!";
                return;
            }

            //ApiService.registerMicrosoft(this.token);
            // Store the token in a global state, local storage, or any desired place.
            this.$store.commit('updateToken', this.token);
            // Redirect to the dashboard after capturing the token
            this.$router.push({ name: "Dashboard" });
        }
    };
</script>

<style scoped>
    /* Add any specific styles for this component if needed */
</style>
<template>
  <ClientLayout
    v-if="answerRecieved"
    :logo="logo"
    :background_color="'#' + background_color"
    :lawfirm_name="lawfirm_name"
    :dark="dark"
    :showNabBar="showNabBar"
  >
    <!-- Questionnaire container -->
    <v-container
      v-if="questionnaire && ref_valid"
      id="questionnaireContainer"
      :style="'display: ' + showQuestionnaire"
    >
      <div
        id="pf"
        data-prefill-inherit="1"
        :data-prefill="dataPrefill"
        data-scroll-offset="67"
        :data-paperform-id="questionnaire"
        class="h-100"
        spinner="1"
      ></div>
    </v-container>
    <!-- Accept terms msg -->
    <v-container
      v-if="questionnaire && ref_valid"
      fill-height
      :style="'display: ' + showAcceptTermsMsg"
    >
      <v-row align="center" justify="center">
        <v-col xs="10" sm="10" md="8" xl="6">
          <v-card elevation="3" outlined light class="mt-auto">
            <v-card-title
              class="py-2"
              :style="'background:#' + background_color"
            >
              <v-layout justify-center>
                <v-img
                  alt="Logo"
                  class="shrink mr-2"
                  contain
                  :src="require(`@/assets/LawfirmsLogos/${logo}`)"
                  transition="scale-transition"
                  height="100"
                  max-width="350"
                />
              </v-layout>
            </v-card-title>
            <v-divider />
            <v-card-text align="center">
              <v-col cols="10" class="text-body-1" style="color:black">
                <h6 class="text-h3">{{ questionnaireWebText.title }}</h6>
                <p class="text-subtitle-2 mb-0">
                  {{ questionnaireWebText.titleLead }}
                </p>
                <img
                  style="height:200px; width:auto;"
                  src="@/assets/icons/kyc-know-your-customer.png"
                />
                <p class="clearfix text-subtitle-2">
                  {{ questionnaireWebText.mainParagraph }}
                </p>
              </v-col>
            </v-card-text>
            <v-card-actions class="justify-center mb-2">
              <v-btn type="submit" color="primary" @click="acceptTerms">
                {{ questionnaireWebText.startButton }}
                <v-icon right>mdi-arrow-left-circle-outline</v-icon>
              </v-btn>
            </v-card-actions>
            <v-divider />
            <v-card-actions v-if="lawyer_email">
              <v-card-text align="center" class="text-caption">
                {{ questionnaireWebText.lawyerAssistantPar }}
                <br />
                <v-icon>mdi-email</v-icon>
                <a :href="'mailto:' + lawyer_email">{{ lawyer_email }}</a>
              </v-card-text>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <!-- Error msg container -->
    <v-container
      fill-height
      :style="'display: ' + showErrorMsg"
      id="errorMessegeContainer"
    >
      <v-row align="center" justify="center">
        <v-col xs="10" md="6" xl="4">
          <v-card elevation="3" outlined light class="mt-auto">
            <v-card-title class="primary white--text py-2">
              <v-layout justify-center>
                <v-icon dark size="20px" class="ml-3">
                  mdi-shield-alert-outline</v-icon
                >
                <h4>{{ msg["msg_header"] }}</h4>
              </v-layout>
            </v-card-title>
            <v-divider />
            <v-card-text align="center">
              <v-col cols="10" class="text-body-1" style="color:black">
                {{ msg["msg_text"] }}
              </v-col>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions v-if="lawyer_email">
              <v-card-text align="center" class="text-caption">
                {{ questionnaireWebText.lawyerAssistantPar }}
                <br />
                <v-icon>mdi-email</v-icon>
                <a :href="'mailto:' + lawyer_email">{{ lawyer_email }}</a>
              </v-card-text>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </ClientLayout>
</template>

<script>
import ApiService from "@/services/ApiService.js";
import ClientLayout from "@/components/ClientLayout.vue";
export default {
  name: "questionnaire",
  components: {
    ClientLayout,
  },
  data() {
    return {
      questionnaireWebText: {
        title: "טופס הכרת הלקוח",
        titleLead: "שאלון אינטראקטיבי למילוי טופס הכרת הלקוח בקלות",
        mainParagraph:
          "טרם מתן שירות עסקי נדרש נותן שירות ובכלל זה, עורך דין, לבצע הליך זיהוי והכרת לקוח. על פי החוק, את הטופס צריך הלקוח למלא בעצמו. על מנת להקל על התהליך ולפשט אותו עבורך, הכנו שאלון אינטראקטיבי שבסיומו המערכת תייצר עבורך טופס ערוך ותשלח אותו אליך ולעורך הדין שלך במייל.",
        lawyerAssistantPar:
          "לבירור וקבלת סיוע ניתן לפנות לעורך הדין שהפנה אותך",
        startButton: "שנתחיל?",
      },
      currentRef: "",
      showNabBar: false,
      showErrorMsg: "none",
      showAcceptTermsMsg: "none",
      showQuestionnaire: "none",
      answerRecieved: null,
      ref_valid: false,
      questionnaire: null,
      lawyer_email: null,
      lang: "",
      //msg
      msg: {},
      // defualt values
      logo: "logo-cligal.png",
      lawfirm_name: "Cligal forms",
      lawfirm_favicon: "cligal-favicon.png",
      background_color: "141758",
      dark: true,
    };
  },
  mounted() {
    // Mount paperform in background
    var script = document.createElement("script");
    script.src = "https://paperform.co/__embed.min.js";
    document.body.appendChild(script);
  },
  methods: {
    acceptTerms() {
      this.showAcceptTermsMsg = "none";
      this.showQuestionnaire = "unset";
      this.showNabBar = true;
      ApiService.refClientEnter(this.currentRef);
    },
  },
  computed: {
    dataPrefill() {
      return `lawyerEmailAddress=${this.lawyer_email}`;
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // check before enter the route
      // if check failed, you can cancel this routing

      // fake code here
      var ref = to.query.ref;
      vm.currentRef = ref;
      // general faliure msg
      var failMsg = {
        title: "תקלה זמנית",
        msg_text: "תקלה זמנית בגישה לנתונים. אנא נסע מאוחר יותר.",
        msg_header: "תקלה זמנית",
      };
      // API
      ApiService.refCheck(ref)
        .then((response) => {
          var res = response.data;
          vm.answerRecieved = true;
          if (res["alive"]) {
            vm.ref_valid = true;
            vm.alive = res["alive"];
            vm.ref = res["id"];
            vm.lawyer_email = res["lawyer_email"];
            vm.logo = res["logo"];
            vm.lawfirm_name = res["lawfirm_name"];
            vm.lawfirm_favicon = res["ilawfirm_favicond"];
            vm.background_color = res["background_color"];
            vm.dark = res["dark"];
            vm.questionnaire = res["questionnaire"];
            if (
              res["questionnaire"] in
              ["knowyourclient-english", "knowyourclient"]
            ) {
              vm.showAcceptTermsMsg = "unset";
            } else {
              vm.acceptTerms();
            }

            vm.lang = res["lang"];
            // get page text
            ApiService.getWebText("questionnaire_into_" + vm.lang)
              .then((response) => {
                vm.questionnaireWebText = response.data;
              })
              .catch((error) => {
                console.log("There was errors: " + error);
              });
          } else {
            vm.lawyer_email = res["lawyer_email"];
            vm.lawfirm_name = res["lawfirm_name"];
            ApiService.getWebText("qs-not-alive")
              .then((response) => {
                vm.msg = response.data;
              })
              .catch((error) => {
                console.log("There was errors: " + error);
                vm.msg = failMsg;
              });
            vm.showErrorMsg = "unset";
          }
          if (!vm.answerRecieved) {
            vm.msg = failMsg;
            vm.showErrorMsg = "unset";
          }
          next();
        })
        .catch((error) => {
          console.log("There was errors: " + error);
        });
    });
  },
};
</script>

<style lang="scss" scoped></style>

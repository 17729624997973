<template>
  <LawyerLayout>
    <v-container fill-height>
      <v-row align="center" justify="center">
        <v-col xs="10" md="6" xl="4">
          <v-card elevation="3" outlined light class="mt-auto">
            <v-card-title class="primary white--text py-2">
              <v-layout justify-center>
                <v-icon dark size="20px" class="ml-3"> mdi-lock</v-icon>
                <h4>איפוס סיסמה</h4>
              </v-layout>
            </v-card-title>
            <v-divider />
            <v-card-text align="center">
              <v-col cols="10">
                <v-form
                  v-if="!msg.msg"
                  @submit.prevent="onSubmit"
                  id="reset-form"
                >
                  <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    label="הזמן את האימייל שלך"
                    type="text"
                    prepend-icon="mdi-account-circle"
                    hint="אנא הזן את האימייל שאיתו נרשמת למערכת. במידה והמייל קיים
                    במערכת, נשלח אליך לינק לאיפוס סיסמה."
                    persistent-hint
                  />
                  <vue-recaptcha
                    ref="recaptcha"
                    @verify="onVerify"
                    @expired="onExpired"
                    size="invisible"
                    :sitekey="sitekey"
                    :loadRecaptchaScript="true"
                  />
                </v-form>
                <v-alert v-if="msg.msg" dense outlined :type="msg.type">
                  {{ msg.msg }}
                </v-alert>
              </v-col>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions v-if="!msg.msg">
              <v-btn to="Register">אין לך חשבון?</v-btn>
              <v-spacer></v-spacer>
              <v-btn
                type="submit"
                outlined
                :loading="loading"
                :disabled="loading"
                color="info"
                @click="loader = 'loading'"
                form="reset-form"
              >
                <v-icon right>mdi-lock</v-icon>
                שלח
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </LawyerLayout>
</template>

<script>
import LawyerLayout from "@/components/LawyerLayout.vue";
import ApiService from "@/services/ApiService.js";
import VueRecaptcha from "vue-recaptcha";

export default {
  components: {
    LawyerLayout,
    VueRecaptcha
  },
  data() {
    return {
      email: "",
      msg: { type: "", msg: "" },
      loading: false,
      sitekey: process.env.VUE_APP_RECAPTCHA_SITEKEY,
      emailRules: [
        v => !!v || "אנא הזן כתובת מייל",
        v =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "כתובת מייל לא חוקית"
      ]
    };
  },
  methods: {
    resetPassword(recaptchaToken) {
      this.loading = true;
      ApiService.resetPassword(this.email, recaptchaToken)
        .then(response => {
          if (response.status === 200) {
            this.loading = false;
            this.msg = {
              type: "success",
              msg: "נשלח אלייך מייל לאיפוס סיסמה"
            };
          }
        })
        .catch(err => {
          this.loading = false;
          if (
            err.response.status === 400 &&
            err.response.data.email[0] ===
              "There is no active user associated with this e-mail address or the password can not be changed"
          ) {
            this.msg = {
              type: "warning",
              msg: "כתובת המייל לא קיימת במערכת"
            };
          } else {
            this.msg = {
              type: "warning",
              msg: err.response.data
            };
          }
        });
    },
    // handle the recaptcha logic
    onSubmit: function() {
      this.$refs.recaptcha.execute();
    },
    onVerify: function(recaptchaToken) {
      this.$refs.recaptcha.reset();
      this.resetPassword(recaptchaToken);
    },
    onExpired: function() {
      this.$refs.recaptcha.reset();
    },
    resetRecaptcha() {
      this.$refs.recaptcha.reset(); // Direct call reset method
    }
  }
};
</script>

<style lang="scss" scoped></style>

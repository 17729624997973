import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import LazyTube from "vue-lazytube";

import "@mdi/font/css/materialdesignicons.css";

Vue.use(LazyTube);

Vue.config.productionTip = false;

// REGISTER COMPONENTS GLOBALLY
import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";

const requireComponent = require.context(
  // The relative path of the components folder
  "./components",
  // Whether or not to look in subfolders
  false,
  // The regular expression used to match base component filenames
  /Base[A-Z]\w+\.(vue|js)$/
);
requireComponent.keys().forEach((fileName) => {
  // Get component config
  const componentConfig = requireComponent(fileName);

  // Get PascalCase name of component
  const componentName = upperFirst(
    camelCase(
      // Gets the file name regardless of folder depth
      fileName
        .split("/")
        .pop()
        .replace(/\.\w+$/, "")
    )
  );
  // Register component globally
  Vue.component(
    componentName,
    // Look for the component options on `.default`, which will
    // exist if the component was exported with `export default`,
    // otherwise fall back to module's root.
    componentConfig.default || componentConfig
  );
});

// API INTERCEPTORS - FOR AUTH
axios.interceptors.response.use(
  (response) => {
    store.dispatch("inspectToken");
    return response;
  },
  (error) => {
    const originalRequest = error.config;
    // Reject promise if usual error
    if (error.response.status !== 401) {
      return Promise.reject(error);
    }
    /*
     * When response code is 401, try to refresh the token.
     * Eject the interceptor so it doesn't loop in case
     * token refresh causes the 401 response
     */
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      return store.dispatch("inspectToken").catch((error) => {
        store.dispatch("logout");
        router.push("/login");
        return Promise.reject(error);
      });
    }
  }
);

// THE VUE INSTANCE
new Vue({
  router,
  store,
  vuetify,
  created() {
    // Automatc login
    const jwtString = localStorage.getItem("t");
    if (jwtString) {
      this.$store.commit("updateToken", jwtString);
    }
    document.title = "Cligal forms";
  },
  render: (h) => h(App),
}).$mount("#app");

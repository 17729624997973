<template>
  <LawyerLayout>
    <Header icon="view-dashboard" text="הפעילות שלי"></Header>
    <v-container style="max-width:1200px;padding-top: 50px;">
      <!-- <v-divider class="my-5" /> -->
      <template>
  <div>
    <v-row class="justify-center align-center">
      <v-col cols="6">
        <v-row>
          <v-col cols="12">
            <DashboardStats />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6" class="d-flex flex-column justify-center">
        <v-row>
          <v-col cols="12">
            <NewReferenceDialog />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <NewQuestionnaireDialog />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

      <v-divider class="my-5" />
      <v-row class="justify-center">
        <v-col>
          <SubmissionsDataTable />
        </v-col>
      </v-row>
      <v-divider class="my-5" />
      <v-row class="justify-center">
        <v-col>
          <ReferencesDataTable />
        </v-col>
      </v-row>
    </v-container>
  </LawyerLayout>
</template>

<script>
import LawyerLayout from "@/components/LawyerLayout.vue";
import ReferencesDataTable from "@/components/ReferencesDataTable.vue";
import SubmissionsDataTable from "@/components/SubmissionsDataTable.vue";
import NewReferenceDialog from "@/components/NewReferenceDialog.vue";
import NewQuestionnaireDialog from "@/components/NewQuestionnaireDialog.vue";
import DashboardStats from "@/components/DashboardStats.vue"
import Header from "../components/Header.vue";

export default {
  name: "Dashboard",
  components: {
    LawyerLayout,
    DashboardStats,
    ReferencesDataTable,
    SubmissionsDataTable,
    NewReferenceDialog,
    Header,
    NewQuestionnaireDialog
},
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>

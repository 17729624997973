import axios from "axios";

var relativeUrl = "";
if (window.location.hostname == "localhost") {
  relativeUrl = "http://localhost:8000/api";
} else if (window.location.hostname == "form.cligal.com") {
  relativeUrl = "https://form.cligal.com/api";
} else {
  relativeUrl = "https://" + window.location.hostname + "/api";
}

const baseURL = relativeUrl;

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

// function
export default {
  getAllData() {
    return axios.get(`${baseURL}/terrorlist-data/`, {
      withCredentials: false,
      headers: headers,
    });
  },
  getDataQuery(data) {
    return axios.post(
      `${baseURL}/bulk-search-terror/`,
      Object.assign(data, data)
    );
  },
  getLawyerReferences() {
    return axios.get(`${baseURL}/references/`, {
      withCredentials: false,
      headers: headers,
    });
  },
  getLawyerSubmissions() {
    return axios.get(`${baseURL}/submissions/`, {
      withCredentials: false,
      headers: headers,
    });
  },
  getLawyerStatistics() {
    return axios.get(`${baseURL}/lawyerstatistics/`, {
      withCredentials: false,
      headers: headers,
    });
  },
  sendNotificationEmail(data) {
    const url = `${baseURL}/send-notification/`;

    const sendgridData = {
      to_emails: data.client_email,
      template_name: "client new form notification - hebrew",
      context: {
        client: data.client_name,
        client_email: data.client_email,
        lawyer_email: data.lawyer_email,
      },
    };

    console.log(sendgridData);

    // const headers = {
    //   'Content-Type': 'application/json',
    // };

    var defaultHeaders = {
      withCredentials: false,
      headers: headers,
    };
    return axios.post(url, Object.assign(sendgridData, defaultHeaders));
  },
  createNewReference(data) {
    var defualtHeaders = {
      withCredentials: false,
      headers: headers,
    };
    return axios.post(
      `${baseURL}/references/`,
      Object.assign(data, defualtHeaders)
    );
  },
  deleteReference(ref) {
    var defualtHeaders = {
      withCredentials: false,
      headers: headers,
    };
    return axios.delete(`${baseURL}/refdetails/${ref}`, defualtHeaders);
  },
  deleteSubmission(sub) {
    var defaultHeaders = {
      withCredentials: false,
      headers: headers,
    };
    return axios.delete(`${baseURL}/subdetails/${sub}`, defaultHeaders);
  },
  refCheck(ref) {
    return axios.get(`${baseURL}/refcheck`, {
      withCredentials: false,
      headers: headers,
      params: { ref: ref },
    });
  },
  refClientEnter(ref) {
    return axios.get(`${baseURL}/refvisit`, {
      withCredentials: false,
      headers: headers,
      params: { ref: ref },
    });
  },
  getWebText(name) {
    return axios.get(`${baseURL}/webtext`, {
      withCredentials: false,
      headers: headers,
      params: { name: name },
    });
  },
  changePassword(old_password, new_password) {
    return axios.put(`${baseURL}/change-password/`, {
      withCredentials: false,
      headers: headers,
      params: {
        old_password: old_password,
        new_password: new_password,
      },
    });
  },
  resetPassword(email, recaptchaToken) {
    return axios.post(`${baseURL}/reset_password/`, {
      withCredentials: false,
      headers: headers,
      email: email,
      recaptchaToken: recaptchaToken,
    });
  },
  resetPasswordConfirm(token, new_password) {
    return axios.post(`${baseURL}/reset_password/confirm/`, {
      withCredentials: false,
      headers: headers,
      token: token,
      password: new_password,
    });
  },
  resetPasswordTokenCheckValid(token) {
    return axios.post(`${baseURL}/reset_password/validate_token/`, {
      withCredentials: false,
      headers: headers,
      token: token,
    });
  },
  getProfile() {
    return axios.get(`${baseURL}/profile/`, {
      withCredentials: false,
      headers: headers,
      params: {},
    });
  },
  getTemplates() {
    // console.log("trying to retreive templates");
    return axios.get(`${baseURL}/templates/`, {
      withCredentials: false,
      headers: headers,
      params: {},
    });
  },
  deleteTemplate(templateId) {
    console.log("tempalte id" + templateId);

    var defaultHeaders = {
      withCredentials: false,
      headers: headers,
    };
    return axios.delete(`${baseURL}/temdetails/${templateId}`, defaultHeaders);
  },
  updateProfile(first_name, last_name, admin_email, admin_name) {
    return axios.put(`${baseURL}/profile/`, {
      withCredentials: false,
      headers: headers,
      params: {
        first_name: first_name,
        last_name: last_name,
        admin_email: admin_email,
        admin_name: admin_name,
      },
    });
  },
  async uploadTemplates(formDataArray) {
    const jsonObjects = [];

    for (let i = 0; i < formDataArray.length; i++) {
      const formData = formDataArray[i];
      const file = formData.get('file');
      if (file instanceof File) {
        console.log(`File ${i + 1}:`, file.name);

        const fileContent = file.text();

        const jsonObject = {
          file: file.name,
          content: await fileContent,
          // Add other properties/values as needed
        };
        jsonObjects.push(jsonObject);
      }
    }
    const jsonData = JSON.stringify(jsonObjects);
    //console.log(jsonData);
    return axios.post(`${baseURL}/templates/`, jsonData, {
      withCredentials: false,
      headers: headers,
    });
  },
  getDownloadUrl(templateId) {
    console.log("trying to dowonload templateID" + templateId)
    return axios.get(`${baseURL}/templates/${templateId}/download`, {
      withCredentials: false,
      headers: headers,
      responseType: 'blob',
    })
      .then(response => {
        // Create a temporary URL for the blob response
        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        const downloadUrl = window.URL.createObjectURL(blob);

        console.log(downloadUrl)
        return downloadUrl;
      });
  },
  getTemplateFields(templateName) {
    const url = `${baseURL}/temdetails/${templateName}`;
    //console.log(`trying to retrieve template fields ${url}`);
    return axios.get(url, {
      withCredentials: false,
      headers: headers,
      params: {},
    });
  },
  register(data) {
    return axios.post(`${baseURL}/register/`, {
      withCredentials: false,
      headers: headers,
      params: data,
    });
  },
  getQuestionnaire() {
    return axios.get(`${baseURL}/questionnaires/`, {
      withCredentials: false,
      headers: headers,
      params: {},
    });
  },
  getQuestionnaireDetails(questionnaireId) {
    //console.log("trying to retreive questionnaire details");
    return axios.get(`${baseURL}/questDetails/${questionnaireId}/`, {
      withCredentials: false,
      headers: headers,
      params: {},
    });
  },
  getParagraphs() {
    console.log("trying to retreive paragraphs");
    return axios.get(`${baseURL}/paragraphs/`, {
      withCredentials: false,
      headers: headers,
      params: {},
    });

  },
  createParagraph(data) {
    console.log("try to save paragraph");
    var defualtHeaders = {
      withCredentials: false,
      headers: headers,
    };

    return axios.post(`${baseURL}/paragraphs/`, Object.assign(data, defualtHeaders), {
      withCredentials: false,
      headers: headers,
    });
  },
  updateParagraph(paragraphId, paragraph_content, paragraph_tags, paragraph_name) {
    return axios.put(`${baseURL}/pardetails/${paragraphId}/`, {
      withCredentials: false,
      headers: headers,
      params: {
        content: paragraph_content,
        tags: paragraph_tags,
        display_name: paragraph_name,
      },
    });
  },
  deleteParagraph(paragraphId) {
    console.log("paragraphId id " + paragraphId);
    var defaultHeaders = {
      withCredentials: false,
      headers: headers,
    };
    return axios.delete(`${baseURL}/pardetails/${paragraphId}`, defaultHeaders);
  }
};

<template>
  <v-container fluid style="background: #1847e8;">
    <v-row class="mb-2">
      <v-col class="text-center">
        <div class="text-h3 mt-5" style="color:white">
          <v-icon x-large dark class="px-3">mdi-{{ icon }}</v-icon>
          <b>{{ text }}</b>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    icon: String,
    text: String
  }
};
</script>

<style></style>

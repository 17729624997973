<template>
  <v-app>
    <LawyerNavBar />
    <v-main><slot></slot></v-main>
    <v-footer dark padless style="margin-top: 50px;">
      <v-card flat tile color="primary" class="text-center" width="100%">
        <v-card-text>
          <v-btn
            v-for="(icon, index) in icons"
            :key="index"
            class="mx-4 white--text"
            icon
            :href="icon.href"
          >
            <v-icon size="24px"> mdi-{{ icon.name }} </v-icon>
          </v-btn>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
import LawyerNavBar from "@/components/LawyerNavBar.vue";
export default {
  name: "LawyerLayout",

  components: {
    LawyerNavBar
  },

  data: () => ({
    links: ["ממשק ניהול", "חיפוש בטבלה", "כניסה"],
    icons: [
      { name: "facebook", href: "https://www.facebook.com/Cligal/" },
      { name: "linkedin", href: "https://www.linkedin.com/company/cligal" }
    ]
    //
  })
};
</script>

<template>
  <LawyerLayout>
    <v-container fill-height>
      <v-row align="center" justify="center">
        <v-col xs="10" md="6" xl="4">
          <v-card elevation="3" outlined light class="mt-auto">
            <v-card-title class="primary white--text py-2">
              <v-layout justify-center>
                <v-icon dark size="20px" class="ml-3"> mdi-lock</v-icon>
                <h4>כניסה למערכת</h4>
              </v-layout>
            </v-card-title>
            <v-divider />
            <v-card-text align="center">
              <v-col cols="10">
                <v-form @submit.prevent="onSubmit" id="login-form">
                  <v-text-field
                    v-model="username"
                    label="אימייל"
                    :rules="emailRules"
                    prepend-icon="mdi-account-circle"
                  />
                  <v-text-field
                    v-model="password"
                    :type="showPassword ? 'text' : 'password'"
                    label="סיסמה"
                    prepend-icon="mdi-lock"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPassword = !showPassword"
                  />
                </v-form>
                <v-alert
                  class="mt-5"
                  v-for="(item, index) in error"
                  :key="index"
                  dense
                  outlined
                  type="warning"
                >
                  {{ item }}
                </v-alert>
              </v-col>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn text to="/Register">אין לך חשבון?</v-btn>
              <v-btn text to="/Reset-password">שכחתי סיסמה</v-btn>
              <v-spacer></v-spacer>
              <v-btn
                type="submit"
                outlined
                :loading="loginLoading"
                :disabled="loginLoading"
                color="info"
                @click="loader = 'loginLoading'"
                form="login-form"
              >
                <v-icon right>mdi-lock</v-icon>
                כניסה
              </v-btn>
              <vue-recaptcha
                ref="recaptcha"
                @verify="onVerify"
                @expired="onExpired"
                size="invisible"
                :sitekey="sitekey"
                :loadRecaptchaScript="true"
              />
            </v-card-actions>
          </v-card>


          <!-- New Card with SSO Buttons -->
          <v-card elevation="3" outlined light class="mt-10">
              <v-card-title class="primary white--text py-2">
                <v-layout justify-center>
                  <v-icon dark size="20px" class="ml-3"> mdi-lock</v-icon>
                  <h4>או כניסה באמצעות</h4>
                  </v-layout>
              </v-card-title>
              <v-card-text align="center">
                  <v-row class="mt-5">
                      <v-col class="text-center" cols="12">
                          <v-btn
                              large
                              color="primary"
                              dark
                              @click="loginWithMicrosoft"
                              class="sso-button"
                          >
                              <v-icon left>mdi-microsoft</v-icon>
                              כניסה עם Microsoft
                          </v-btn>
                      </v-col>
                  </v-row>
                  <v-row>
                      <v-col class="text-center" cols="12">
                          <v-btn
                              large
                              color="red"
                              dark
                              @click="loginWithGoogle"
                              class="mb-5 sso-button"
                          >
                              <v-icon left>mdi-google</v-icon>
                              כניסה עם Google
                          </v-btn>
                      </v-col>
                  </v-row>
                  <!-- <v-row>
                      <v-col class="text-center" cols="12">
                          <v-btn
                              large
                              color="blue darken-4"
                              dark
                              @click="loginWithFacebook"
                              class="mb-5 sso-button"
                          >
                              <v-icon left>mdi-facebook</v-icon>
                              כניסה עם Facebook
                          </v-btn>
                      </v-col>
                  </v-row> -->
              </v-card-text>
          </v-card>



        </v-col>
      </v-row>
    </v-container>
  </LawyerLayout>
</template>

<script>
import LawyerLayout from "@/components/LawyerLayout.vue";
import VueRecaptcha from "vue-recaptcha";

export default {
  components: {
    LawyerLayout,
    VueRecaptcha
  },
  data() {
    return {
      username: "",
      password: "",
      error: [],
      loginLoading: false,
      showPassword: false,
      sitekey: process.env.VUE_APP_RECAPTCHA_SITEKEY,
      emailRules: [
        v => !!v || "אנא הזן כתובת מייל",
        v =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "כתובת מייל לא חוקית"
      ]
    };
  },
  methods: {
    login(recaptchaToken) {
      this.error = [];
      this.$store
        .dispatch("login", {
          username: this.username,
          password: this.password,
          recaptchaToken: recaptchaToken
        })
        .then(() => {
          this.$router.push({ name: "Dashboard" });
        })
        .catch(err => {
          if (err.response.status === 400) {
            for (var item in err.response.data) {
              for (var j = 0; j < err.response.data[item].length; j++) {
                var currentErr = err.response.data[item][j];
                this.error.push(currentErr);
              }
            }
          }
        });
    },
    // handle the recaptcha logic
    onSubmit: function() {
      this.$refs.recaptcha.execute();
    },
    onVerify: function(recaptchaToken) {
      this.$refs.recaptcha.reset();
      this.login(recaptchaToken);
    },
    onExpired: function() {
      this.$refs.recaptcha.reset();
    },
    resetRecaptcha() {
      this.$refs.recaptcha.reset(); // Direct call reset method
    },
    loginWithGoogle() {
      const url = process.env.NODE_ENV === "production" ? "https://form.cligal.com/api/oidc/login/google/" : "http://localhost:8000/api/oidc/login/google/";
      window.location.href = url;
    },
    loginWithMicrosoft() {
      const url = process.env.NODE_ENV === "production" ? "https://form.cligal.com/api/oidc/login/microsoft/" : "http://localhost:8000/api/oidc/login/microsoft/";
      window.location.href = url;
    },
    loginWithFacebook() {
      window.location.href = "http://localhost:8000/api/oidc/login/facebook/";
    }
  }
};
</script>

<style lang="scss" scoped></style>

<style scoped>
.sso-button {
    width: 220px; /* or whatever width you find appropriate */
}
</style>









<template>
  <LawyerLayout>
    <Header icon="format-align-right" text="הפסקאות שלי" id="form-section"></Header>
    <v-container style="max-width: 1200px;">
      <v-row class="align-center">
        <v-col cols="12" sm="8" class="align-center">
          <v-textarea v-model="newParagraph.content" outlined label="הכנס פסקה חדשה" rows="7" class="pt-8"></v-textarea>
        </v-col>
        <v-col cols="12" sm="4">
          <v-row class="align-end justify-end">
            <v-col cols="12" sm="12" class="justify-end" style="padding:0px;margin:0px">
              <v-text-field v-model="newParagraph.tags" outlined label="תגיות (מופרדות בפסיקים)"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" class="justify-end" style="padding:0px;margin:0px">
              <v-text-field v-model="newParagraph.display_name" outlined label="שם או תיאור הפסקה"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" class="justify-end" style="padding:0px;margin:0px">
              <v-btn type="submit" :loading="loading" color="primary" @click="saveParagraph" class="ml-auto" block>
                <v-icon class="mx-2" right>mdi-content-save</v-icon>
                שמירה
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-if="successMessage">
        <v-col cols="12">
          <v-alert type="success" outlined>{{ successMessage }}</v-alert>
        </v-col>
      </v-row>

      <v-row v-if="errorMessage">
        <v-col cols="12">
          <v-alert type="error" outlined>{{ errorMessage }}</v-alert>
        </v-col>
      </v-row>

      <v-card class="py-3 px-5 mb-4">
        <v-card-title>
          <b class="ml-auto">הפסקאות שלי</b>
          <v-btn outlined color="primary" @click="exportAllParagraphs">
            <v-icon class="ml-2">mdi-download</v-icon>
            <span>
            ייצוא הפסקאות ל-CSV
          </span>
          </v-btn>

        </v-card-title>
        <v-divider />
        <v-card-text>
          <slot></slot>
        </v-card-text>

        <v-container fluid>
          <v-row>
            <v-col cols="12" v-for="paragraph in paragraphs" :key="paragraph.id">
              <v-card outlined class="mb-4">
                <v-card-title class="font-weight-bold">
                  <span>{{ paragraph.display_name }}</span>
                </v-card-title>
                <v-card-subtitle>
                  <!-- <span>יוצר:</span>
                  <span v-if="paragraph.uploaded_by">
                    <span>{{ paragraph.uploaded_by.first_name }}</span>
                    <span>{{ paragraph.uploaded_by.last_name }}</span>
                  </span>
                  <span v-else>
                    Unknown User
                  </span> -->
                  <span>עודכן: </span>
                  <span>{{ formatDateTime(paragraph.updated_at) }}</span>
                  <span v-if="paragraph.tags && paragraph.tags.length > 0">
                    <span> | </span>
                    <span>תגיות: {{ paragraph.tags.join(", ") }}</span>
                  </span>

                </v-card-subtitle>
                <v-card-text>
                  <span style="color:black; font-size: 18px;">{{ paragraph.content }}</span>
                </v-card-text>
                <v-card-actions>
                  <v-btn text color="primary" @click="editParagraph(paragraph.id)">
                    עריכה
                  </v-btn>
                  <v-btn text color="error" @click="deleteParagraph(paragraph.id)">
                    מחיקה
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>






      </v-card>
    </v-container>
  </LawyerLayout>
</template>

<script>
import moment from "moment";
import LawyerLayout from "@/components/LawyerLayout.vue";
import Header from "../components/Header.vue";
import ApiService from "@/services/ApiService.js";

export default {
  name: "ParagraphsList",
  data() {
    return {
      loading: false,
      paragraphs: [],
      newParagraph: {
        content: "", // Initialize with an empty string or any default value
        tags: "",
        display_name: "",
        display_ref: ""
      },
      successMessage: "",
      errorMessage: "",
      isNewItem: true,
      paragraphId: null, // Initialize paragraphId as null
    };
  },
  components: {
    LawyerLayout,
    Header,
  },
  created() {
    this.fetchParagraphs();
  },
  methods: {
    fetchParagraphs() {
      ApiService.getParagraphs()
        .then((response) => {
          this.paragraphs = response.data;
          //this.fetchLawyerDataForParagraphs();

          console.log(this.paragraphs);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // fetchLawyerDataForParagraphs() {
    //   const lawyerIds = this.paragraphs.map(paragraph => paragraph.uploaded_by);
    //   const uniqueLawyerIds = [...new Set(lawyerIds)]; // Remove duplicate lawyer IDs

    //   const promises = uniqueLawyerIds.map(lawyerId => {
    //     return axios.get(`/api/lawyers/${lawyerId}/`)
    //       .then(response => response.data)
    //       .catch(error => {
    //         console.error(`Error fetching lawyer data for ID ${lawyerId}:`, error);
    //         return null;
    //       });
    //   });

    //   Promise.all(promises)
    //     .then(results => {
    //       const lawyerDataMap = {};
    //       results.forEach(lawyerData => {
    //         if (lawyerData) {
    //           lawyerDataMap[lawyerData.id] = lawyerData;
    //         }
    //       });

    //       // Assign lawyer data to each paragraph
    //       this.paragraphs.forEach(paragraph => {
    //         const lawyerId = paragraph.uploaded_by;
    //         paragraph.lawyer = lawyerDataMap[lawyerId] || null;
    //       });

    //       console.log(this.paragraphs);
    //     })
    //     .catch(error => {
    //       console.error('Error fetching lawyer data:', error);
    //     });
    // },
    saveParagraph() {

      if (typeof this.newParagraph.content === 'string') {
        if (this.newParagraph.content.trim() === "") {
          this.errorMessage = "יש להכניס טקסט לפסקה";
          return;
        }
      }
      console.log("try to insert paragraph");
      this.loading = true;
      this.errorMessage = "";
      this.successMessage = "";
      console.log(this.newParagraph.tags);
      this.newParagraph.tags = this.newParagraph.tags.split(',');

      if (this.isNewItem === false) {
        const paragraphId = this.paragraphId; // Access the paragraphId from the component's data
        ApiService.updateParagraph(paragraphId, this.newParagraph.content, this.newParagraph.tags, this.newParagraph.display_name)
          .then((response) => {
          if (response.status === 200) {
            this.ResetAfterSave();
          }
        })
        .catch((error) =>{
          console.log(error);
          this.errorMessage = "אירעה שגיאה בשמירת הפסקה";
           this.successMessage = "";
           this.loading = false;
        });
      }
      else {
        ApiService.createParagraph(this.newParagraph)
          .then((response) => {
            if (response.status === 201) {
              this.ResetAfterSave();
            }
            else {
              this.errorMessage = "אירעה שגיאה בשמירת הפסקה";
              this.successMessage = "";
            }
          })
          .catch((error) => {
            console.log(error);
            this.errorMessage = "אירעה שגיאה בשמירת הפסקה";
            this.successMessage = "";
            this.loading = false;
          });
      }
    },
    editParagraph(paragraphId) {
      // Find the paragraph in the array
      const paragraph = this.paragraphs.find((p) => p.id === paragraphId);
      if (!paragraph) {
        // Handle error - paragraph not found
        return;
      }

      // Load the content of the paragraph to the relevant fields
      this.newParagraph.content = paragraph.content;
      this.newParagraph.tags = paragraph.tags.join(",");
      console.log(this.newParagraph.tags);
      this.newParagraph.display_name = paragraph.display_name;

      // Set the paragraphId property in the component's data
      this.paragraphId = paragraphId;


      // Scroll to the form section
      const formElement = document.getElementById('form-section');
      console.log(formElement);
      if (formElement) {
        this.isNewItem = false;
        formElement.scrollIntoView({ behavior: 'smooth' });
      }

    },
    ResetAfterSave() {
      this.newParagraph.content = "";
      this.newParagraph.tags = "";
      this.newParagraph.display_name = "";
      this.successMessage = "הפסקה נשמרה בהצלחה";
      this.errorMessage = "";
      this.loading = false;

      // Fetch the updated paragraph list
      this.fetchParagraphs();
    },
    deleteParagraph(paragraphId) {
      console.log(paragraphId)
      // Perform the delete operation
      ApiService.deleteParagraph(paragraphId)
        .then(() => {
          // Remove the deleted template from the current list
          this.paragraphs = this.paragraphs.filter(paragraph => paragraph.id !== paragraphId);

          // Refresh the template list by fetching the updated list from the API
          ApiService.getParagraphs()
            .then(response => {
              this.paragraphs = response.data;

              console.log("Paragraph deleted successfully.");
            })
            .catch(error => {
              console.log("Error fetching Paragraphs after deletion:", error);
            });
        })
        .catch(error => {
          console.log("Error deleting Paragraph:", error);
        })
        .catch((error) => {
          // Handle error - e.g., show error message
          console.log("Error deleting paragraph", error);
        });
    },
    formatDateTime(dateTime) {
      return moment(dateTime).format("DD/MM/YYYY HH:mm:ss");
    },
    //export all paragraph
    exportAllParagraphs(){
      const csvData = this.paragraphs.map((paragraph) => {
        return [
          paragraph.content,
          paragraph.tags.join(","),
          paragraph.display_name,
          this.formatDateTime(paragraph.updated_at),
        ];
      });

        const csvHeader = ["Content", "Tags", "Display Name", "Updated At"];

        // Combine the header and data rows
        const csvRows = [csvHeader, ...csvData];

        // Convert the CSV rows to a properly encoded CSV string
        const csvContent = "\uFEFF" + csvRows.map(row => row.join(",")).join("\n");

        // Create a Blob with the CSV content
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

        // Generate a unique filename
        const timestamp = moment().format("YYYYMMDD_HHmmss");
        const filename = `paragraphs_${timestamp}.csv`;

      // Create a temporary link element
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;

      // Programmatically click the link to trigger the download
      link.click();

      // Cleanup the temporary link
      window.URL.revokeObjectURL(link.href);
    }
  },
};
</script>
<meta charset="UTF-8"/> 

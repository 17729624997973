<template>
  <v-app>
    <ClientNavBar
      :logo="logo"
      :background_color="background_color"
      :dark="dark"
      :lawfirm_name="lawfirm_name"
      :showNabBar="showNabBar"
    />
    <v-main><slot></slot></v-main>
  </v-app>
</template>

<script>
import ClientNavBar from "@/components/ClientNavBar.vue";
export default {
  name: "ClientLayout",
  components: {
    ClientNavBar
  },
  props: ["logo", "background_color", "dark", "lawfirm_name", "showNabBar"],
  data() {
    return {
      icons: [
        { name: "facebook", href: "https://www.facebook.com/Cligal/" },
        { name: "linkedin", href: "https://www.linkedin.com/company/cligal" }
      ]
    };
  }
};
</script>

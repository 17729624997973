<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "App"
};
</script>

<style>
@font-face {
  font-family: "Assistant";
  src: local("Assistant"),
    url(./fonts/Assistant/static/Assistant-Regular.ttf) format("truetype");
}
</style>

<template>
  <v-data-table
    :headers="headers"
    :items="submissions"
    :loading="DataIsLoading"
    sort-by="created_at"
    :sort-desc="true"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat color="primary">
        <v-toolbar-title class="white--text"
          ><b>תשובות שהתקבלו</b></v-toolbar-title
        >
        <v-divider class="mx-4" inset vertical></v-divider>
        <span class="text-h8 white--text">{{ submissionsCount }} שאלונים שמולאו על ידי הלקוחות</span>
        <v-spacer></v-spacer>



        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline">
                למחוק את השורה?</v-card-title
              >
              <!-- <v-card-text
                >חשוב לדעת - 
                חדש.</v-card-text
              > -->
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >בטל</v-btn
                >
                <v-btn color="red" outlined @click="deleteItemConfirm"
                  >מחק</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog> 
      </v-toolbar>
    </template>
    <template v-slot:item.created_at="{ item }">
      {{ format_date(item.created_at) }}
    </template>
    <template v-slot:item.is_done="{ item }">
      <v-icon v-if="item.is_done" color="green">mdi-check</v-icon>
      <v-icon v-else color="red">mdi-close</v-icon>
    </template>
    <template v-slot:item.questionnaire="{ item }">
      <v-chip outlined>
        {{ item.questionnaire }}
      </v-chip>
    </template>
    <template v-slot:item.actions="{ item }">
      <div style="display: flex;">
      <!-- Risk assesment -->
      <div v-if="item.questionnaire === 'טופס הכר את הלקוח'">
        <v-tooltip bottom v-if="item.riskassessment_pdf_link">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              :href="item.riskassessment_pdf_link"
              target="_blank"
              color="primary"
              icon
              class="pdf-icon"
              style="margin-left: 5px;"
            >
              <v-img
                src="@/assets/icons/pdf-icon.png"
                class="d-inline-block align-top"
                style="width: 20px; aspect-ratio: auto; ::v-deep img.pdf-icon { max-height: 20px; }"
              ></v-img>
            </v-btn>

          </template>
          <span>צפה במסמך הערכת הסיכון</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              :to="
                '/qs-lawyer/kyc-risk-assessment?client_name=' +
                  item.sender_name +
                  '&pubsub=' +
                  item.pubsub +
                  '&lawyerFullName=' +
                  lawyer_fullname +
                  '&lawyer_email=' +
                  username
              "
              icon
              class="ml-2"
            >
              <v-icon v-if="item.riskassessment_pdf_link">mdi-refresh</v-icon>
              <v-icon color="primary" v-else>mdi-file-document-edit</v-icon>
            </v-btn>
          </template>
          <span v-if="item.riskassessment_pdf_link"
            >התחל הליך חדש של הערכת סיכון</span
          >
          <span v-else>הליך הערכת סיכון</span>
        </v-tooltip>
      </div>
      <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              @click="deleteItem(item)"
              class="mx-2"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>מחק</span>
        </v-tooltip>
        </div> 
    </template>
  </v-data-table>
</template>

<script>
import moment from "moment";
import ApiService from "@/services/ApiService.js";
export default {
  data: () => ({
    snackbarShow: false,
    DataIsLoading: true,
    dialogDelete: false,
    headers: [
      {
        text: "הלקוח שאליו הופנה השאלון",
        value: "ref_client",
        align: "center",
      },
      {
        text: "סוג השאלון",
        value: "questionnaire",
        align: "center",
      },
      { text: "שם הלקוח שמילא השאלון", value: "sender_name", align: "center" },
      {
        text: "אימייל לקוח",
        value: "mailing_list[client_email]",
        align: "center",
      },
      { text: "תאריך מילוי השאלון", value: "created_at", align: "center" },
      {
        text: "פעולות",
        value: "actions",
        align: "center",
      },
    ],
    submissions: [],
    editedIndex: -1,
    editedItem: {
      client_name: "",
    },
    defaultItem: {
      client_name: "",
    },
    username: "",
    lawyer_fullname: "",
    riskUser: false,
    submissionsCount: 0,
  }),

  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  created() {
    ApiService.getLawyerSubmissions()
      .then((response) => {
        this.DataIsLoading = false;
        this.submissions = response.data;
        this.submissionsCount = this.submissions.length;
      })
      .catch((error) => {
        console.log("There was errors: " + error);
      });
    ApiService.getProfile()
      .then((response) => {
        var res = response.data;
        this.username = res["user"]["username"];
        this.lawyer_fullname = `${res["first_name"]} ${res["last_name"]}`;
      })
      .catch((error) => {
        console.log("There was errors: " + error);
      });
  },
  methods: {
    deleteItem(item) {
      this.editedIndex = this.submissions.indexOf(item);
      this.editedItem = Object.assign({}, item);
      console.log(this.editedItem);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      ApiService.deleteSubmission(this.editedItem["pubsub"])
        .then(response => {
          if (response.status == 204) {
            this.submissions.splice(this.editedIndex, 1);
            this.closeDelete();
          }
        })
        .catch(error => {
          console.log("There was errors: " + error);
          this.closeDelete();
        });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    getColor(num) {
      if (num > 0) {
        return "green";
      } else {
        return "red";
      }
    },
    // getColorQuestionnaire(name) {
    //   if (name === "טופס הכר את הלקוח") {
    //     return "primary";
    //   } else {
    //     return "";
    //   }
    // },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pdf-icon {
  width: 8px;
  height: auto;
}



</style>

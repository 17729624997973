<template>
  <LawyerLayout>
    <Header icon="shield-search" text="חיפוש במאגרי מידע"></Header>
    <v-container style="max-width:1200px;">
      <v-tabs
        v-model="tab"
        background-color="transparent"
        centered
        icons-and-text
      >
        <v-tabs-slider></v-tabs-slider>
        <v-tab href="#tab-1">
          חיפוש חופשי במאגר
          <v-icon color="primary">mdi-shield-search</v-icon>
        </v-tab>
        <v-tab href="#tab-2">
          חיפוש מרובה עם קובץ אקסל
          <v-icon color="primary">mdi-file</v-icon>
        </v-tab>
      </v-tabs>
      <v-divider class="mb-3"></v-divider>
      <v-tabs-items v-model="tab">
        <v-tab-item key="1" value="tab-1">
          <v-row>
            <v-col align="center" justify="center">
              <!-- <v-img width="120px" height="auto" src="@/assets/security_check.png" /> -->
              <h1><b>חיפוש ברשימת הארגונים והפעילים החשודים</b></h1>
              הטבלה מכילה את רשימת פעילי וארגוני הטרור המעודכנת ליוני 2022.
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-card>
                <v-card-subtitle class="primary">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="חפש"
                    single-line
                    hide-details
                    dark
                  >
                  </v-text-field>
                </v-card-subtitle>
                <v-data-table
                  :headers="headers"
                  :items="rows"
                  :search="search"
                  :loading="isLoading"
                >
                  <template v-slot:item.name_he="{ item }">
                    <span v-if="item.name_aka_he">
                      {{ item.name_he }}
                      <small>(ידוע גם כ-״{{ item.name_aka_he }}״)</small>
                    </span>
                    <span v-else>
                      {{ item.name_he }}
                    </span>
                  </template>
                  <template v-slot:item.name_eng="{ item }">
                    <span v-if="item.name_aka_eng">
                      {{ item.name_eng }}
                      <small>(ידוע גם כ-״{{ item.name_aka_eng }}״)</small>
                    </span>
                    <span v-else>
                      {{ item.name_eng }}
                    </span>
                  </template>
                  <template v-slot:item.id1="{ item }">
                    <span v-if="item.id2">
                      {{ item.id1 }} ({{ item.id2 }})
                    </span>
                    <span v-else>
                      {{ item.id1 }}
                    </span>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item key="2" value="tab-2">
          <v-row justify="center">
            <v-col cols="12" lg="6" align="center" justify="center">
              <!-- <v-img width="120px" height="auto" src="@/assets/security_check.png" /> -->
              <h1><b>טעינת ערכים לחיפוש</b></h1>
              <v-file-input
                v-model="file"
                outlined
                persistent-hint
                hint="טען קובץ ולחץ על לחצן החיפוש"
                @click:append="validateXlsx"
                @click:clear="resetXslxSelection"
                append-icon="mdi-shield-search"
                accept=".xls,.xlsx"
                label="טען קובץ"
                :rules="fileRules"
              >
              </v-file-input>
              <v-alert v-if="xlsxSearchBadColumns" outlined dense type="error"
                >הקובץ שנטען אינו מכיל את העמודות הדרושות - id ו-name.</v-alert
              >
              <v-alert
                v-if="!xlsxSearchResultsReturned"
                outlined
                dense
                type=" info"
                >{{ xlsxInfo }}</v-alert
              >
            </v-col>
          </v-row>
          <v-progress-linear
            v-if="isXlsxSearchResultLoading"
            indeterminate
            color="primary"
          ></v-progress-linear>
          <v-row v-if="xlsxSearchResultsReturned" justify="center">
            <h1><b>תוצאות החיפוש</b><br /></h1>
            <v-col cols="12" lg="8">
              <v-alert
                v-if="!xlsxSearchResults"
                prominent
                text
                outlined
                centered
                type="success"
              >
                לא אותרו התאמות חשודות בין רשימת הלקוחות שהזנת למאגר
              </v-alert>
              <v-alert
                v-if="xlsxSearchResults"
                prominent
                outlined
                align="center"
                type="warning"
              >
                אותרו התאמות חשודות בין רשימת הלקוחות שהזנת למאגר. נדרשת בדיקה
                מעמיקה של התוצאות המפורטות מטה
              </v-alert>
            </v-col>
          </v-row>
          <v-row v-if="xlsxSearchResultsReturned" justify="center">
            <v-col cols="10" align="center" justify="center">
              <v-data-table
                :headers="xlsxSearchResultsHeaders"
                :items="xlsxSearchResults"
                :loading="isXlsxSearchResultLoading"
                class="elevation-1"
              >
                <template v-slot:item.searchOptions="{ item }">
                  <li
                    v-for="searchOption in item.searchOptions"
                    :key="searchOption.id"
                  >
                    {{ searchOption.value }} | התאמה
                    <v-chip
                      :color="getColor(searchOption.rank)"
                      dark
                      x-small
                      outlined
                    >
                      <b>{{ getDescription(searchOption.rank) }}</b>
                    </v-chip>
                  </li>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </LawyerLayout>
</template>

<script>
import LawyerLayout from "@/components/LawyerLayout.vue";
import ApiService from "@/services/ApiService.js";
import Header from "../components/Header.vue";
import * as XLSX from "xlsx";

export default {
  name: "TerrorListSearch",
  components: {
    LawyerLayout,
    Header
  },
  data() {
    return {
      file: "",
      xlsxSearchBadColumns: false,
      tab: null,
      tabsItems: ["search", "xlsxSearch"],
      isLoading: true,
      isXlsxSearchResultLoading: false,
      xlsxSearchResultsReturned: false,
      search: "",
      xlsxInfo:
        "החיפוש מבוצע באמצעות מנגנון חיפוש ״עומק״ שמטרתו לאתר התאמות במאגר גם במקרים שבהם שם הלקוח לא הוקלד בצורה מדויקת. הקובץ צריך להכיל שתי עמודות בלבד - name ו-id. כלומר, עמודה אחת עבור שם הלקוח (יחיד / חברה) ועמודה נוספת עבור מספר הזיהוי (לדוג׳ ת.ז., ח.פ.וכו׳)",
      headers: [
        { text: "שם בעברית", value: "name_he", width: "43%" },
        { text: "שם באנגלית", value: "name_eng", width: "43%" },
        { text: "מספר מזהה", value: "id1", width: "14%" }
      ],
      xlsxSearchResultsHeaders: [
        { text: "שם ומס׳ זיהוי", value: "name", width: "30%" },
        {
          text: "התאמות חשודות במאגר (+ציון ההתאמה)",
          value: "searchOptions",
          width: "80%"
        }
      ],
      rows: [],
      xlsxSearchResults: [],
      fileRules: [
        v => !!v || "נדרש לטעון קובץ תקין",
        v => (v && v.size > 0) || "נדרש לטעון קובץ תקין"
      ]
    };
  },
  created() {
    ApiService.getAllData()
      .then(response => {
        this.isLoading = false;
        this.rows = response.data;
      })
      .catch(error => {
        console.log("There was errors: " + error);
      });
  },
  methods: {
    getColor(rank) {
      if (rank > 60) return "red";
      else if (rank > 40) return "orange";
      else return "green";
    },
    getDescription(rank) {
      if (rank > 60) return "גבוהה";
      else if (rank > 40) return "בינונית";
      else return "נמוכה";
    },
    resetXslxSelection() {
      this.isXlsxSearchResultLoading = false;
      this.xlsxSearchResultsReturned = false;
      this.xlsxSearchResults = [];
    },
    validateXlsx() {
      this.isXlsxSearchResultLoading = true;
      this.xlsxSearchResultsReturned = false;
      this.xlsxSearchBadColumns = false;
      this.xlsxSearchResults = [];
      if (this.file) {
        const reader = new FileReader();

        reader.onload = e => {
          /* Parse data */
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, { type: "binary" });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_json(ws, {
            raw: true,
            defval: null
          });
          var entries = [];
          /* Check data */
          if (data.length < 2) {
            this.resetXslxSelection();
            return;
          }
          for (const row of data) {
            if (!("name" in row) || !("id" in row)) {
              this.xlsxSearchBadColumns = true;
              this.resetXslxSelection();
              return;
            }

            for (const t of ["id", "name"]) {
              if (row[t]) {
                entries.push({ keyword: row[t], type: t, row: row });
              }
            }
          }

          /* Send request*/
          ApiService.getDataQuery(entries)
            .then(response => {
              var rawResults = response.data.results;
              var results = [];
              console.log(rawResults);
              rawResults.forEach(element => {
                var name = element.row.name;
                if (element.row.id) {
                  name = `${name} (${element.row.id})`;
                }

                var searchOptions = [];
                element.results.forEach(option => {
                  searchOptions.push({
                    rank: option.ratio,
                    value: option.value
                  });
                });
                results.push({ name: name, searchOptions: searchOptions });
              });
              this.xlsxSearchResultsReturned = true;
              this.xlsxSearchResults = results;
              this.isXlsxSearchResultLoading = false;
            })
            .catch(error => {
              console.log("There was errors: " + error);
            });
        };

        reader.readAsBinaryString(this.file);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>

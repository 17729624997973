<template>
  <LawyerProfilePageLayout title="שינוי סיסמה">
    <v-form v-model="valid" @submit.prevent="changePassword">
      <v-row>
        <v-col>
          <v-text-field
            v-model="oldpassword"
            label="סיסמה נוכחית"
            :type="showoldpassword ? 'text' : 'password'"
            outlined
            clearable
            required
            :append-icon="showoldpassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showoldpassword = !showoldpassword"
          ></v-text-field>
          <v-text-field
            v-model="newpassword"
            label="סיסמה חדשה"
            :type="shownewpassword ? 'text' : 'password'"
            outlined
            clearable
            required
            :rules="passwordRules"
            :append-icon="shownewpassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="shownewpassword = !shownewpassword"
          ></v-text-field>

          <div class="text-subtitle-2 mb-5">
            הסיסמה אינה יכולה להיות דומה לשם משתמש שלך.<br />
            הסיסמה צריכה להיות לפחות באורך של 8 תווים.<br />
            הסיסמה אינה יכולה להיות אחת מהסיסמות הנפוצות ביותר.<br />
            הסיסמה אינה יכולה להכיל רק מספרים בלבד.<br />
          </div>
          <v-text-field
            v-model="confirmPassword"
            label="ווידוא סיסמה חדשה"
            :type="showconfirmPassword ? 'text' : 'password'"
            outlined
            clearable
            required
            :rules="confirmPasswordRules"
            :append-icon="showconfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showconfirmPassword = !showconfirmPassword"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col align="center">
          <v-btn
            type="submit"
            :loading="loading"
            :disabled="!valid"
            color="success"
            @click="loader = 'loading'"
          >
            <v-icon class="mx-2" right>mdi-lock</v-icon>
            החלף סיסמה
          </v-btn>
          <v-alert
            class="mt-5"
            v-for="(item, index) in error"
            :key="index"
            dense
            outlined
            type="warning"
          >
            <span v-for="i in item" :key="i">
              {{ i }}
            </span>
          </v-alert>
        </v-col>
      </v-row>
    </v-form>
  </LawyerProfilePageLayout>
</template>

<script>
// @ is an alias to /src
import LawyerProfilePageLayout from "@/components/LawyerProfilePageLayout.vue";
import ApiService from "@/services/ApiService.js";

export default {
  name: "Password change",
  data() {
    return {
      valid: false,
      error: "",
      loading: false,
      oldpassword: "",
      newpassword: "",
      confirmPassword: "",
      showoldpassword: false,
      shownewpassword: false,
      showconfirmPassword: false,
      passwordRules: [
        value => !!value || "אנא  הזן סיסמה חדשה",
        value =>
          (value && value.length >= 8) ||
          "הסיסמה קצרה מדי (נדרש לפחות 8 תווים)",
        value => /(?!^\d+$)^.+$/.test(value) || "הסיסמה מכילה רק מספרים"
      ],
      confirmPasswordRules: [
        value => !!value || "אנא הזן שוב את הסיסמה החדשה",
        value => value === this.newpassword || "הסיסמאות שהזנת אינן זהות"
      ]
    };
  },
  components: {
    LawyerProfilePageLayout
  },
  methods: {
    changePassword() {
      this.loading = true;
      ApiService.changePassword(this.oldpassword, this.newpassword)
        .then(response => {
          if (response.status === 204) {
            this.loading = false;
            this.$store.dispatch("logout");
            this.$router.push({ name: "Login" });
          }
        })
        .catch(err => {
          this.loading = false;
          if (err.response.status === 400) {
            this.error = err.response.data;
          }
        });
    }
  }
};
</script>

<template>
  <div>
    <v-dialog v-model="dialog" max-width="500px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn block color="primary" elevation="2" outlined x-large class="mb-2" v-bind="attrs" v-on="on" disabled> 
          <v-icon class="ml-2">mdi-plus-thick</v-icon>
          הוספה או עריכה של שאלון
        </v-btn>
      </template>

      <v-card>
        <v-tabs v-model="tab" grow>
          <v-tab>
            יצירת שאלון חדש
          </v-tab>
          <v-tab>
            עריכת שאלון קיים
          </v-tab>

          <v-tab-item>
            <v-card-text>

              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-select v-model="editedItem.questionnaire_name" outlined persistent-hint solo dense label="טופס"
                      hint="בחר את התבנית מסמך עבור השאלון" :items="templateOptions" :rules="templateOptionsRules" />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                בטל
              </v-btn>
              <v-btn color="primary" @click="navigateToAddPage">
                צור
              </v-btn>
            </v-card-actions>
          </v-tab-item>

          <v-tab-item>
            <v-card-text>

              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-select v-model="editedItem.questionnaire_name" outlined persistent-hint solo dense label="טופס"
                      hint="בחר את התבנית מסמך עבור השאלון" :items="questionnairesOptions"
                      :rules="questionnairesOptionsRules" />
                  </v-col>
                </v-row>


              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                בטל
              </v-btn>
              <v-btn color="primary" @click="navigateToEditPage">
                ערוך
              </v-btn>
            </v-card-actions>



          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-dialog>


    <!-- Snack bar notification bar -->
    <v-snackbar id="simple-snack-bar" width="30vw" v-model="snackbarShow" outlined color="primary"
      transition="fade-transition" style="margin: 0 auto; top: 50%; transform: translateY(-50%);">
      <v-row>
        <v-col cols="12" class="d-flex justify-center align-center">
          <v-icon color="primary">
            mdi-check-circle
          </v-icon>
          נוצר בהצלחה שאלון חדש!
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="5" class="d-flex justify-center align-center">
          <v-btn color="primary" outlined small @click="openLink(snackbarLink)">
            <v-icon class="ml-2" small>
              mdi-open-in-new
            </v-icon>
            פתח קישור
          </v-btn>
        </v-col>
        <v-col cols="5" class="d-flex justify-center align-center">
          <v-btn color="primary" outlined small @click="copyTextToClipboard(snackbarLink)">
            <v-icon class="ml-2" small>
              mdi-content-copy
            </v-icon>
            העתק קישור
          </v-btn>
        </v-col>
        <v-col cols="2" class="d-flex justify-center align-center">
          <v-btn color="primary" icon>
            <v-icon class="ml-2" @click="snackbarShow = false">
              mdi-close-circle-outline
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-snackbar>
  </div>
</template>
<script>
import ApiService from "@/services/ApiService.js";
export default {
  data: () => ({
    snackbarShow: false,
    editedItem: {
      questionnaire_name: "",
      parameters: "",
    },
    defaultItem: {
      questionnaire_name: "",
      send_email: true,
    },
    dialog: false,
    templateOptions: [],
    questionnairesOptions: [],
    formOptionsRules: [(v) => !!v || "בחר טופס"],
    questionnairesOptionsRules: [(v) => !!v || "בחר שאלון"],
    templateOptionsRules: [(v) => !!v || "בחר תבנית"],
    tab: 0,
    questions: [],
  }),
  created() {
    ApiService.getTemplates()
      .then((response) => {
        this.questionnaireLoading = false;
        var templates = [];
        response.data.forEach((element) => {
          templates.push({
            text: element.name,
            value: element.id,
          });
          //console.log(element.name);
        });
        this.templateOptions = templates;
      })
      .catch((error) => {
        console.log("There was errors: " + error);
      });

    ApiService.getQuestionnaire()
      .then((response) => {
        console.log(response.data);
        this.questionnaireLoading = false;
        var questionnaires = [];
        response.data.forEach((element) => {
          questionnaires.push({
            text: element.display_name_heb,
            value: element.short_name,
          });
        });
        this.questionnairesOptions = questionnaires;
      })
      .catch((error) => {
        console.log("There was errors: " + error);
      });
  },
  methods: {
    navigateToEditPage() {
      this.$router.push(`/EditQuestionnaire/${this.editedItem["questionnaire_name"]}`);
    },
    //navigate to Add page
    navigateToAddPage() {
      console.log(this.editedItem["questionnaire_name"].value);
      console.log(this.editedItem["questionnaire_name"]);
      this.$router.push(`/AddQuestionnaire/${this.editedItem["questionnaire_name"]}`);
    },
    //get the questionnaire details from the server using apiService.js getQuestionnaireDetails()
    getQuestionnaireDetails() {
      ApiService.getQuestionnaireDetails(this.editedItem["questionnaire_name"])
        .then((response) => {
          this.questions = response.data;
          console.log(response.data);
          this.questionnaireLoading = false;
        })
        .catch((error) => {
          console.log("There was errors: " + error);
        });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },
    validateForm() {
      return this.$refs.newReferenceForm.validate();
    },
    save() {
      if (this.validateForm()) {
        var newItemData = {
          questionnaire_name: this.editedItem["questionnaire_name"],
          client_name: this.editedItem["client_name"],
        };

        ApiService.createNewReference(newItemData)
          .then((response) => {
            this.snackbarLink = response.data["link"];
            this.snackbarText = response.data["client_name"];
            this.snackbarShow = true;
          })
          .catch((error) => {
            console.log("There was errors: " + error);
          });
        this.close();
      }
    },
    openLink(snackbarLink) {
      window.open(snackbarLink);
    },
  },
};
</script>
<style lang="scss" scoped>
simple-snack-bar span {
  margin: auto;
  text-align: center;
}
</style>

<template>
  <v-app-bar app color="primary" dark flat>
    <div class="d-flex align-center">
      <router-link to="/">
        <v-img
          alt="Logo"
          class="shrink mr-2"
          contain
          src="@/assets/cligal-logo.png"
          transition="scale-transition"
          width="60"
        />
      </router-link>
    </div>

    <v-spacer></v-spacer>
    <!-- <v-btn v-if="loggedIn" icon to="/">
      <v-icon medium>mdi-home</v-icon>
    </v-btn> -->
    <v-btn v-if="loggedIn" icon to="/Dashboard">
      <v-icon medium>mdi-view-dashboard</v-icon>
    </v-btn>
    <v-btn v-if="loggedIn" icon to="/Paragraphs">
      <v-icon medium>mdi-format-align-right</v-icon>
    </v-btn>
    <v-btn v-if="loggedIn" icon to="/Templates">
      <v-icon medium>mdi-file-document</v-icon>
    </v-btn>
    <v-btn v-if="loggedIn" icon to="/List">
      <v-icon medium>mdi-shield-search</v-icon>
    </v-btn>
    <span v-if="!loggedIn">
      <v-btn color="white" light to="/Register">הירשמו בחינם</v-btn>
      <v-btn outlined color="white" to="/Login" class="mr-2"
        >היכנס למערכת</v-btn
      >
    </span>
    <v-btn v-if="loggedIn" icon to="/Profile">
      <v-icon medium>mdi-account</v-icon>
    </v-btn>
    <v-btn v-if="loggedIn" @click="logout" icon>
      <v-icon medium>mdi-logout</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
import { authComputed } from "@/store/helpers.js";
export default {
  computed: {
    ...authComputed
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    }
  }
};
</script>

<style lang="scss" scoped></style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.references,"loading":_vm.referencesDataIsLoading,"sort-by":"created_at","sort-desc":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","dark":"","color":"primary"}},[_c('v-toolbar-title',[_c('b',[_vm._v("הפניות פתוחות")])]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('span',{staticClass:"text-h8"},[_vm._v(_vm._s(_vm.referencesCount)+" הפניות פתוחות שיצרתי למילוי שאלונים")]),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}}),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" למחוק את ההפנייה לשאלון?")]),_c('v-card-text',[_vm._v("חשוב לדעת - במידה ותמחק את ההפנייה לשאלון, הלקוח לא יוכל למלא את השאלון בעזרת הלינק שקיבל ויצטרך לקבל ממך לינק חדש.")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("בטל")]),_c('v-btn',{attrs:{"color":"red","outlined":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("מחק")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.format_date(item.created_at))+" ")]}},{key:"item.client_first_enter_at",fn:function(ref){
var item = ref.item;
return [(item.client_first_enter_at)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close")])]}},{key:"item.alive",fn:function(ref){
var item = ref.item;
return [(item.alive)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close")])]}},{key:"item.stage",fn:function(ref){
var item = ref.item;
return [_c('v-progress-linear',{attrs:{"height":"10","striped":"","value":item.stage * 25,"rounded":"","color":_vm.getStageColor(item.stage)}}),_vm._v(" "+_vm._s(_vm.getStageDescription(item.stage))+" ")]}},{key:"item.count_submissions",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.count_submissions),"outlined":""}},[_vm._v(" "+_vm._s(item.count_submissions)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('NotificationDialog',{attrs:{"link":item.link,"client-name":item.client_name,"questionnaire-name":item.questionnaire_name}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mx-2"},'v-icon',attrs,false),on),[_vm._v(" mdi-bell ")])]}}],null,true)},[_c('span',[_vm._v("שלח תזכורת ללקוח")])])],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mx-2",on:{"click":function($event){return _vm.deleteItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("מחק")])])],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('ul',[_vm._l((item.submissions),function(sub,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(sub)+" ")])}),_c('li')],2)])]}},{key:"no-data",fn:function(){return undefined},proxy:true}])}),_c('v-snackbar',{attrs:{"outlined":"","color":"primary"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"outlined":"","text":""},on:{"click":function($event){_vm.snackbarShow = false}}},'v-btn',attrs,false),[_vm._v(" סגור ")])]}}]),model:{value:(_vm.snackbarShow),callback:function ($$v) {_vm.snackbarShow=$$v},expression:"snackbarShow"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"primary"}},[_vm._v(" mdi-check-circle ")]),_vm._v(" נוצר בהצלחה שאלון חדש עבור "),_c('b',[_vm._v(_vm._s(_vm.snackbarText))]),_c('br'),_vm._v(" העתק את הלינק - "),_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.copyLink(_vm.snackbarLink)}}},[_vm._v(" mdi-content-copy ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <v-dialog v-model="dialog" max-width="500px">
      <template v-slot:activator="{ on, attrs }" >
        <v-btn
          color="primary"
          fab
          small
          v-bind="attrs"
          v-on="on"
          outlined
          style="border:none !important;"
        >
          <v-icon>mdi-bell</v-icon>
        </v-btn>
      </template>
      <v-form ref="notificationForm">
        <v-card>
          <v-card-title class="justify-center">
            <span class="headline"><b>שליחת תזכורת ללקוח {{clientName}} </b></span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">

                <v-card-subtitle class="d-flex align-center" style="padding-left:0; padding-right:0;">
                  <v-row style="padding:0">
                    <v-col cols="3" class="d-flex justify-center">
                      <span>קישור לשאלון <b style="color:black">{{questionnaireName}}</b></span>
                    </v-col>
                    <v-col cols="7" class="d-flex justify-left">
                      <a style="text-align:left" :href="link" target="_blank"><span>{{ link }}</span></a>
                    </v-col>

                    <v-col cols="2" class="d-flex justify-center align-center">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon size="32" @click="copyTextToClipboard(link)" v-bind="attrs" v-on="on">mdi-content-copy</v-icon>
                        </template>
                        <span>העתק קישור</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-card-subtitle>



                  <v-text-field
                    v-model="editedItem.client_email"
                    outlined
                    dense
                    required
                    label="כתובת האימייל של הלקוח"
                    class="mt-2"
                    :rules="clientEmailRules"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close">
              בטל
            </v-btn>
            <v-btn color="primary" @click="send">
              שלח
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <!-- Snack bar notification bar -->
    <v-snackbar
      id="simple-snack-bar"
      width="30vw"
      v-model="snackbarShow"
      outlined
      color="primary"
      transition="fade-transition"
      style="margin: 0 auto; top: 50%; transform: translateY(-50%);"
    >
      <v-row>
        <v-col cols="12" class="d-flex justify-center align-center">
          <v-icon color="primary">
            mdi-check-circle
          </v-icon>
          תזכורת נשלחה ללקוח!
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="5" class="d-flex justify-center align-center">
          <v-btn color="primary" outlined small @click="openLink(snackbarLink)">
            <v-icon class="ml-2" small>
              mdi-open-in-new
            </v-icon>
            פתח קישור
          </v-btn>
        </v-col>
        <v-col cols="5" class="d-flex justify-center align-center">
          <v-btn
            color="primary"
            outlined
            small
            @click="copyTextToClipboard(snackbarLink)"
          >
            <v-icon class="ml-2" small>
              mdi-content-copy
            </v-icon>
            העתק קישור
          </v-btn>
        </v-col>
        <v-col cols="2" class="d-flex justify-center align-center">
          <v-btn color="primary" icon>
            <v-icon class="ml-2" @click="snackbarShow = false">
              mdi-close-circle-outline
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-snackbar>
  </div>
</template>
<script>
import ApiService from "@/services/ApiService.js";
export default {
  name: "NotificationDialog",
  props: {
    link: String,
    clientName: String,
    questionnaireName: String
    },
  data: () => ({
    snackbarShow: false,
    editedItem: {
      questionnaire_name: "",
      client_name: "",
      client_email: "",
      client_tel: "",
    },
    defaultItem: {
      questionnaire_name: "",
      client_name: "",
      client_email: "",
      client_tel: "",
    },
    dialog: false,
    formOptions: [],
    clientNameRules: [(v) => !!v || "נא ציין שם לקוח"],
    clientEmailRules: [
      (v) => !!v || "אנא הזן כתובת מייל",
      (v) =>
        !v ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "כתובת מייל לא חוקית",
    ],
    formOptionsRules: [(v) => !!v || "בחר טופס"],
  }),
  watch: {
    dialog(val) {
      val || this.close();
    },
    // dialogDelete(val) {
    //   val || this.closeDelete();
    // }
  },
  created() {
    // ApiService.getQuestionnaire()
    //   .then((response) => {
    //     this.questionnaireLoading = false;
    //     var questionnaires = [];
    //     response.data.forEach((element) => {
    //       questionnaires.push({
    //         text: element.display_name_heb,
    //         value: element.short_name,
    //       });
    //     });
    //     this.formOptions = questionnaires;
    //   })
    //   .catch((error) => {
    //     console.log("There was errors: " + error);
    //   });
  },
  methods: {
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },
    validateForm() {
      return this.$refs.notificationForm.validate();
    },
    send() {
      if (this.validateForm()) {
        console.log("try to send link to client");
        var newItemData = {
          questionnaire_name: this.editedItem["questionnaire_name"],
          client_name: this.editedItem["client_name"],
        };
        newItemData["client_email"] = this.editedItem["client_email"];
        console.log(newItemData);
        ApiService.sendNotificationEmail(newItemData)
          .then((response) => {
            // this.snackbarLink = response.data["link"];
            this.snackbarText = response.data["client_name"];
            this.snackbarShow = true;
          })
          .catch((error) => {
            console.log("There was errors: " + error);
          });
        this.close();
      }
    },
    copyLink(item) {
      var textArea = document.createElement("textarea");
      textArea.value = item;
      // Avoid scrolling to bottom
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";

      document.body.appendChild(textArea);
      textArea.focus();

      /* Select the text field */
      textArea.select();
      textArea.setSelectionRange(0, 99999); /*For mobile devices*/
      /* Copy the text inside the text field */
      document.execCommand("copy");
    },
    fallbackCopyTextToClipboard(text) {
      var textArea = document.createElement("textarea");
      textArea.value = text;

      // Avoid scrolling to bottom
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        var successful = document.execCommand("copy");
        var msg = successful ? "successful" : "unsuccessful";
        console.log("Fallback: Copying text command was " + msg);
      } catch (err) {
        console.error("Fallback: Oops, unable to copy", err);
      }

      document.body.removeChild(textArea);
    },
    copyTextToClipboard(text) {
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(text);
        return;
      }
      navigator.clipboard.writeText(text).then(
        function() {
          console.log("Async: Copying to clipboard was successful!");
        },
        function(err) {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },
    openLink(snackbarLink) {
      window.open(snackbarLink);
    },
  },
};
</script>
<style lang="scss" scoped>
simple-snack-bar span {
  margin: auto;
  text-align: center;
}
</style>

<template>
  <v-row class="justify-center">
    <v-col
      v-for="(item, index) in statisticsData"
      :key="index"
      md="4"
      sm="6"
      cols="12"
    >
      <v-card
        elevation="2"
        :dark="item.dark"
        color="secondary"
        class="text-center"
      >
        <v-icon x-large :dark="item.dark" color="black" class="pa-2 pt-3">
          {{ item.icon }}
        </v-icon>
        <v-card-title class="justify-center mr-auto pa-1 black--text">
          <b>{{ item.title }}</b>
        </v-card-title>
        <v-card-text class="primary--text">
          <h1>{{ item.value }}</h1>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import ApiService from "@/services/ApiService.js";
export default {
  data() {
    return {
      statisticsData: [
        {
          name: "references_count",
          title: "הפניות שיצרתי",
          icon: "mdi-send",
          dark: false,
          value: 0
        },
        {
          name: "submissions_count",
          title: "טפסים שהתקבלו",
          icon: "mdi-checkbox-marked-circle-outline ",
          dark: false,
          value: 0
        },
        {
          name: "templates_count",
          title: "תבניות שהעליתי",
          icon: "mdi-draw",
          dark: false,
          value: 0
        }
      ]
    };
  },
  created() {
    ApiService.getLawyerStatistics()
      .then(response => {
        this.lawyerStatisticsDataIsLoading = false;
        var res = response.data;
        for (var j = 0; j < this.statisticsData.length; j++) {
          this.statisticsData[j]["value"] = res[this.statisticsData[j]["name"]];
        }
      })
      .catch(error => {
        console.log("There was errors: " + error);
      });
  }
};
</script>

<style lang="scss" scoped></style>

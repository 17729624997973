<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="references"
      :loading="referencesDataIsLoading"
      sort-by="created_at"
      sort-desc
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat dark color="primary">
          <v-toolbar-title><b>הפניות פתוחות</b></v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <span class="text-h8">{{ referencesCount }} הפניות פתוחות שיצרתי למילוי שאלונים</span>
          <v-spacer></v-spacer>
          <!-- הדיאלוג ליצירת שאלון חדש -->
          <v-dialog v-model="dialog" max-width="500px">
            <!-- <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="white"
                outlined
                class="mb-2"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small class="ml-2">mdi-plus-thick</v-icon>
                צור שאלון חדש
              </v-btn>
            </template> -->
            <!-- <v-form ref="newReferenceForm">
              <v-card>
                <v-card-title class="justify-center">
                  <span class="headline"><b>יצירת הפניה שאלון חדש</b></span>
                </v-card-title>

                <v-card-text>
                  <div class="text-caption">
                    עם יצירת הפניה למילוי שאלון תקבל לינק ייחודי שתשתף עם הלקוח
                    שלך. באמצעות הלינק הייחודי הלקוח יוכל למלא את השאלון ואתה
                    תוכל לראות את סטטוס ההתקדמות שלו.
                  </div>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-select
                          v-model="editedItem.questionnaire_name"
                          outlined
                          persistent-hint
                          solo
                          dense
                          label="טופס"
                          hint="בחר את הטופס שאתה מעוניין שהלקוח ימלא"
                          :items="formOptions"
                          :rules="formOptionsRules"
                        />
                        <v-text-field
                          v-model="editedItem.client_name"
                          required
                          outlined
                          dense
                          label="שם הלקוח"
                          hint="הזינו את שם הלקוח אליו מיועד השאלון. ככה תוכלו לעקוב בהמשך האם מילא את השאלון."
                          persistent-hint
                          :rules="clientNameRules"
                        ></v-text-field>
                        <v-switch
                          v-model="editedItem.send_email"
                          label="לשלוח ללקוח לינק לשאלון במייל?"
                          color="primary"
                          value="primary"
                          hide-details
                        ></v-switch>
                        <v-text-field
                          v-if="editedItem.send_email"
                          v-model="editedItem.client_email"
                          outlined
                          dense
                          required
                          label="כתובת האימייל של הלקוח"
                          class="mt-2"
                          :rules="clientEmailRules"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">
                    בטל
                  </v-btn>
                  <v-btn color="primary" @click="save">
                    שמור
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-form> -->
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline">
                למחוק את ההפנייה לשאלון?</v-card-title
              >
              <v-card-text
                >חשוב לדעת - במידה ותמחק את ההפנייה לשאלון, הלקוח לא יוכל למלא
                את השאלון בעזרת הלינק שקיבל ויצטרך לקבל ממך לינק
                חדש.</v-card-text
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >בטל</v-btn
                >
                <v-btn color="red" outlined @click="deleteItemConfirm"
                  >מחק</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog> 
        </v-toolbar>
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ format_date(item.created_at) }}
      </template>
      <template v-slot:item.client_first_enter_at="{ item }">
        <v-icon v-if="item.client_first_enter_at" color="green"
          >mdi-check</v-icon
        >
        <v-icon v-else color="red">mdi-close</v-icon>
      </template>
      <template v-slot:item.alive="{ item }">
        <v-icon v-if="item.alive" color="green">mdi-check</v-icon>
        <v-icon v-else color="red">mdi-close</v-icon>
      </template>
      <!-- <template v-slot:item.has_submitted="{ item }">
      <v-icon v-if="item.has_submitted" color="green">mdi-check</v-icon>
      <v-icon v-else color="red">mdi-close</v-icon>
    </template> -->
      <template v-slot:item.stage="{ item }">
        <v-progress-linear
          height="10"
          striped
          :value="item.stage * 25"
          rounded
          :color="getStageColor(item.stage)"
        >
        </v-progress-linear>
        {{ getStageDescription(item.stage) }}
      </template>
      <template v-slot:item.count_submissions="{ item }">
        <v-chip :color="getColor(item.count_submissions)" outlined>
          {{ item.count_submissions }}
        </v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <div style="display: flex; justify-content: center;">

        <NotificationDialog :link="item.link" :client-name="item.client_name" :questionnaire-name="item.questionnaire_name">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="mx-2"
              >
                mdi-bell
              </v-icon>
            </template>
            <span>שלח תזכורת ללקוח</span>
          </v-tooltip>
        </NotificationDialog>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              @click="deleteItem(item)"
              class="mx-2"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>מחק</span>
        </v-tooltip>
        </div>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <ul>
            <li v-for="(sub, index) in item.submissions" :key="index">
              {{ sub }}
            </li>
            <li></li>
          </ul>
        </td>
      </template>
      <template v-slot:no-data>
        <!-- להוסיף פה משהו -->
      </template>
    </v-data-table>
    <!-- Snack bar notification bar -->
    <v-snackbar v-model="snackbarShow" outlined color="primary">
      <v-icon small class="mr-2" color="primary">
        mdi-check-circle
      </v-icon>
      נוצר בהצלחה שאלון חדש עבור <b>{{ snackbarText }}</b
      ><br />
      העתק את הלינק -
      <v-icon class="mr-2" @click="copyLink(snackbarLink)">
        mdi-content-copy
      </v-icon>
      <template v-slot:action="{ attrs }">
        <v-btn outlined text v-bind="attrs" @click="snackbarShow = false">
          סגור
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import moment from "moment";
import ApiService from "@/services/ApiService.js";
import NotificationDialog from "@/components/NotificationDialog.vue";
export default {
  components: {
    NotificationDialog,
  },
  data: () => ({
    snackbarShow: false,
    snackbarText: "",
    snackbarLink: "",
    expanded: [],
    dialog: false,
    dialogDelete: false,
    singleExpand: true,
    referencesDataIsLoading: true,
    headers: [
      { text: "לקוח", value: "client_name", align: "center" },
      { text: "טופס", value: "questionnaire_name", align: "center" },
      // { text: "תאריך יצירה", value: "created_at", align: "center" },
      // { text: "שאלון פתוח למילוי?", align: "center", value: "alive" },
      // { text: "התקבל מענה", value: "has_submitted", align: "center" },
      { text: "התקדמות", value: "stage", align: "center", width: "30%" },
      // {
      //   text: "תאריך יצירה",
      //   value:  format_date(item.created_at) ,
      //   align: "center"
      // },
      // {
      //   text: "משתמש נכנס לשאלון",
      //   value: "client_first_enter_at",
      //   align: "center"
      // },
      { text: "פעולות", value: "actions", sortable: false, align: "center" }
    ],
    formOptions: [
      { text: "הכר את הלקוח", value: "knowyourclient" },
      { text: "Know your client", value: "knowyourclient-english" }
    ],
    references: [],
    editedIndex: -1,
    editedItem: {
      questionnaire_name: "",
      client_name: "",
      client_email: "",
      client_tel: ""
    },
    defaultItem: {
      client_name: ""
    },
    // Form validation Rules
    clientNameRules: [v => !!v || "נא ציין שם לקוח"],
    clientEmailRules: [
      v => !!v || "אנא הזן כתובת מייל",
      v =>
        !v ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "כתובת מייל לא חוקית"
    ],
    formOptionsRules: [v => !!v || "בחר טופס"],
    referencesCount: 0,
  }),

  watch: {
    dialog(val) {
      val || this.close();
    }
    // dialogDelete(val) {
    //   val || this.closeDelete();
    // }
  },

  created() {
    ApiService.getLawyerReferences()
      .then(response => {
        this.referencesDataIsLoading = false;
        this.references = response.data;
        this.referencesCount = this.references.length;
      })
      .catch(error => {
        console.log("There was errors: " + error);
      });
  },

  methods: {
    // editItem(item) {
    //   this.editedIndex = this.references.indexOf(item);
    //   this.editedItem = Object.assign({}, item);
    //   this.dialog = true;
    // },

    deleteItem(item) {
      this.editedIndex = this.references.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
      console.log(this.editedIndex);
    },
    copyLink(item) {
      var textArea = document.createElement("textarea");
      textArea.value = item;
      // Avoid scrolling to bottom
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";

      document.body.appendChild(textArea);
      textArea.focus();

      /* Select the text field */
      textArea.select();
      textArea.setSelectionRange(0, 99999); /*For mobile devices*/
      /* Copy the text inside the text field */
      document.execCommand("copy");
    },

    deleteItemConfirm() {
      ApiService.deleteReference(this.editedItem["id"])
        .then(response => {
          if (response.status == 204) {
            this.references.splice(this.editedIndex, 1);
            this.referencesCount--;
            this.closeDelete();
          }
        })
        .catch(error => {
          console.log("There was errors: " + error);
          this.closeDelete();
        });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    validateForm() {
      return this.$refs.newReferenceForm.validate();
    },
    // save() {
    //   if (this.editedIndex > -1) {
    //     console.log("edit item");
    //   } else {
    //     if (this.validateForm()) {
    //       this.referencesDataIsLoading = true;
    //       var newItemData = {
    //         questionnaire_name: this.editedItem["questionnaire_name"],
    //         client_name: this.editedItem["client_name"]
    //       };
    //       if (
    //         this.editedItem["send_email"] &&
    //         this.editedItem["client_email"]
    //       ) {
    //         newItemData["client_email"] = this.editedItem["client_email"];
    //       }
    //       ApiService.createNewReference(newItemData)
    //         .then(response => {
    //           this.referencesDataIsLoading = false;
    //           this.references.push(response.data);
    //           this.snackbarLink = response.data["link"];
    //           this.snackbarText = response.data["client_name"];
    //           this.snackbarShow = true;
    //         })
    //         .catch(error => {
    //           console.log("There was errors: " + error);
    //         });
    //       this.close();
    //     }
    //   }
    // },
    getStageDescription(num) {
      var des = {
        "0": "שאלון לא פעיל",
        "1": "נוצר שאלון",
        "2": "השאלון נשלח ללקוח במייל או sms",
        "3": "הלקוח נכנס לשאלון",
        "4": "הלקוח מילא שאלון בהצלחה והטופס נשלח אליך למייל"
      };
      return des[num];
    },
    getStageColor(num) {
      var des = {
        "0": "red",
        "1": "primary",
        "2": "primary",
        "3": "primary",
        "4": "primary"
      };
      return des[num];
    },
    getColor(num) {
      if (num > 0) {
        return "green";
      } else {
        return "red";
      }
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>

<template>
  <v-btn-toggle mandatory dense tile group color="primary">
    <v-btn v-for="item in drawerItems" :key="item.title" :to="item.link" link>
      {{ item.title }}
    </v-btn>
  </v-btn-toggle>
</template>

<script>
export default {
  name: "Lawyer-Profile-Nav-drawer",
  data() {
    return {
      drawerItems: [
        { icon: "", title: "פרטים אישיים", link: "/Profile" },
        { icon: "", title: "שינוי סיסמה", link: "/Change-password" }
      ]
    };
  }
};
</script>

<style lang="scss" scoped></style>

<template>
    <LawyerLayout>
    <v-card-text>
        <!-- Content for editing an existing questionnaire -->
        <v-container>
            <!-- <v-row>
                <v-col cols="12" sm="12" md="12">
                    <v-select v-model="editedItem.questionnaire_name" outlined persistent-hint solo dense label="טופס"
                        hint="בחר את התבנית מסמך עבור השאלון" :items="questionnairesOptions"
                        :rules="questionnairesOptionsRules" />
                </v-col>
            </v-row>
 -->
            <!-- Conditionally show the button and text if questionnaire_name is not empty -->
            <v-row v-if="questionnaire_name">
                <v-col cols="12" sm="12" md="12">
                    <v-btn color="primary" @click="getQuestionnaireDetails">
                        <v-icon left>mdi-check</v-icon>
                        שמור שינויים
                    </v-btn>
                </v-col>
            </v-row>

            <!-- Conditionally show the text if questions are not empty -->
            <v-row>
                <v-col v-for="question in questions" :key="question.key" cols="12">
                    <v-card v-if="question.type !== 'choices'">
                        <!-- Render question information -->
                        <v-card-text>
                            {{ question.title }}
                        </v-card-text>
                        <v-card-text>
                            <!-- Render different input components based on the question type -->
                            <template v-if="question.type === 'text'">
                                <!-- Text input field -->
                                <v-text-field v-model="question.title" label="שאלה" outlined></v-text-field>
                                <v-text-field v-model="question.description" label="תיאור (לא חובה)"
                                    outlined></v-text-field>
                            </template>

                            <template v-else-if="question.type === 'choices'">
                                <v-select v-model="question.answer" :label="question.title" :items="question.options"
                                    outlined></v-select>
                            </template>
                            <!-- Add more conditions for other question types as needed -->
                        </v-card-text>
                        <v-card-text>
                            <v-row align="center">
                                <v-col cols="auto">
                                    שדה חובה:
                                </v-col>
                                <v-col cols="auto">
                                    <v-switch v-model="question.required"></v-switch>
                                </v-col>
                            </v-row>
                        </v-card-text>

                        <v-card-text>
                            סוג שדה: {{ question.type }}
                        </v-card-text>
                        <v-card-text>
                            מפתח: {{ question.custom_key }}
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col v-if="questions && questions.length === 0" cols="12">
                    No fields available.
                </v-col>
            </v-row>
        </v-container>
    </v-card-text>
</LawyerLayout>

</template>

<script>

//import { useRouter } from 'vue-router';
import ApiService from "@/services/ApiService.js";
import LawyerLayout from "@/components/LawyerLayout.vue";



export default {
    props: {
    questionnaireName: String,
    },
    data() {
        return {
            questionnaire_name: '',
            questions: [],
        };
    },
    components: {
    //LawyerProfilePageLayout,
    LawyerLayout,
  },
    mounted() {
    //const router = useRouter();
    const questionnaireName = this.$route.params.questionnaireName;
    this.questionnaire_name = questionnaireName;
    this.getQuestionnaireDetails();
  },
    methods: {
        getQuestionnaireDetails() {
            ApiService.getQuestionnaireDetails(this.questionnaire_name)
                .then((response) => {
                    this.questions = response.data;
                    console.log(response.data);
                    this.questionnaireLoading = false;
                })
                .catch((error) => {
                    console.log("There were errors: " + error);
                });
        },
    },
};
</script>
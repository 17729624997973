<template>
  <LawyerLayout>
    <v-container fluid class="pt-0">
      <v-row calss="mt-0" style="background: #efefef">
        <v-col class="text-center">
          <div class="text-h5 mt-0">
            <v-btn icon to="/Dashboard">
              <v-icon medium color="primary"
                >mdi-arrow-right-thick</v-icon
              > </v-btn
            >|
            <b>שאלון הערכת סיכון ללקוח</b>
            |
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  medium
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  class="mx-2"
                  @click="copyText(url)"
                >
                  mdi-content-copy
                </v-icon>
              </template>
              <span>העתק את הלינק לשאלון</span>
            </v-tooltip>
          </div>
          <div class="text mt-0">
            הלקוח: <b>{{ $route.query.client_name }}</b>
          </div>
        </v-col>
      </v-row>
      <div
        id="pf"
        data-prefill-inherit="1"
        data-scroll-offset="152"
        data-paperform-id="kyc-risk-assessment"
        class="h-100"
        spinner="1"
      ></div>
    </v-container>
  </LawyerLayout>
</template>

<script>
import LawyerLayout from "@/components/LawyerLayout.vue";

export default {
  name: "Dashboard",
  components: {
    LawyerLayout
  },
  data() {
    return {
      url: location.href,
      questionnaire: "kyc-risk-assessment"
    };
  },
  mounted() {
    // Mount paperform in background
    var script = document.createElement("script");
    script.src = "https://paperform.co/__embed.min.js";
    document.body.appendChild(script);
  },
  methods: {
    copyText(text) {
      navigator.clipboard.writeText(text);
    }
  }
};
</script>

<style lang="scss" scoped></style>

import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import jwt_decode from "jwt-decode";

Vue.use(Vuex);

// HTTP for dev and HTTPS for other
var relativeUrl = "";
if (window.location.hostname == "localhost") {
  relativeUrl = "http://localhost:8000/api";
} else {
  relativeUrl = "https://" + window.location.hostname + "/api";
}
const baseURL = relativeUrl;

export default new Vuex.Store({
  state: {
    jwt: null,
    endpoints: {
      obtainJWT: `${baseURL}/api-token-auth/`,
      refreshJWT: `${baseURL}/api-token-refresh/`
    }
  },
  mutations: {
    updateToken(state, newToken) {
      state.jwt = newToken;
      localStorage.setItem("t", newToken);
      axios.defaults.headers.common["Authorization"] = `JWT ${newToken}`;
    },
    removeToken() {
      localStorage.removeItem("t");
      location.reload();
    }
  },
  actions: {
    login({ commit }, credentials) {
      return axios
        .post(this.state.endpoints.obtainJWT, credentials)
        .then(response => {
          commit("updateToken", response.data.token);
        });
    },
    logout({ commit }) {
      commit("removeToken");
    },
    refreshToken() {
      const payload = {
        token: this.state.jwt
      };
      axios
        .post(this.state.endpoints.refreshJWT, payload)
        .then(response => {
          this.commit("updateToken", response.data.token);
        })
        .catch(error => {
          console.log(error);
          this.dispatch("logout");
        });
    },
    inspectToken() {
      const token = this.state.jwt;
      if (token) {
        const decoded = jwt_decode(token);
        const exp = decoded.exp;
        const orig_iat = decoded.orig_iat;
        // IF it is expiring in 30 minutes (1800 second) AND it is not reaching its lifespan (7 days — 30 mins = 630000–1800 = 628200) => REFRESH
        if (
          exp - Date.now() / 1000 < 3600 &&
          Date.now() / 1000 - orig_iat < 628200
        ) {
          this.dispatch("refreshToken");
        }
        // IF it is expring in more than 30 minutes - then do nothing
        else if (exp - Date.now() / 1000 > 3600) {
          // DO NOTHING, ITS OK
        }
        //IF it has expired => DO NOT REFRESH / PROMPT TO RE-OBTAIN TOKEN
        else {
          this.dispatch("logout");
          this.$router.push("/login");
        }
      }
    }
  },
  modules: {},
  getters: {
    loggedIn(state) {
      return !!state.jwt;
    }
  }
});

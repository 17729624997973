<template>
  <v-app-bar app :color="background_color" :dark="dark" v-if="showNabBar">
    <div class="d-flex align-center">
      <v-img
        alt="Logo"
        class="shrink mr-2"
        contain
        :src="require(`@/assets/LawfirmsLogos/${logo}`)"
        transition="scale-transition"
        width="60"
      />
    </div>

    <v-spacer></v-spacer>
    <v-toolbar-title>{{ lawfirm_name }}</v-toolbar-title>
  </v-app-bar>
</template>

<script>
export default {
  props: ["logo", "background_color", "dark", "lawfirm_name", "showNabBar"],
  methods: {}
};
</script>

<style lang="scss" scoped></style>

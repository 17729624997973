<template>
  <LawyerLayout>

    
    <v-container pb-5 pt-5 fluid style="background: #1847e8; min-height:400px">
  <v-row justify="center" align="center">
    <v-col xs="12" md="5" lg="5" class="text-right">
      <div class="text-h3 mt-5 white--text">
        <b>טפסים דיגיטליים</b>
      </div>
      <div class="text-subtitle-1 my-5 white--text">
        <h4>
          מערכת להפקת מסמכים וטפסים באופן אוטומטי על בסיס מידע שנאסף מלקוחות באופן דיגיטלי
        </h4>
      <br/>
        <span style="font-size:18px">
        במקום לבזבז זמן על הפקה חוזרת של אותם מסמכים, שלחו שאלונים אינטראקטיביים, נוחים ופשוטים למילוי, אשר ימולאו על-ידי הלקוחות ויהפכו, 
       למסמכים מושלמים שמוכנים לשימוש לכל תרחיש.  
      </span>
      <h3 class="my-5 white--text">התחילו עכשיו ב-30 יום ניסיון ללא
        התחייבות.</h3>
      </div>
      <!--make buttons bold-->

      <v-btn large color="white" to="/Register" style="font-weight: bold;">הירשמו בחינם</v-btn>
      <v-btn large outlined color="white" to="/Login" class="mr-2" style="font-weight: bold;"
        >כניסה למערכת</v-btn
      >
    </v-col>
    <v-col xs="12" md="5" lg="5" class="justify-center">
      <v-carousel
        height="50vh"
        max-height="275"
        cycle
        hide-delimiter-background
        show-arrows-on-hover
        hide-delimiters
      >
        <v-carousel-item
          v-for="(item, i) in items"
          :key="i"
          :src="item.src"
          contain
        ></v-carousel-item>
      </v-carousel>

      <!-- <v-img
        contain
        max-height="275"
        src="@/assets/icons/macbook.png"
      ></v-img> -->
    </v-col>
  </v-row>
</v-container>






    <v-divider></v-divider>
    <v-container pb-15 mt-5>
      <v-row>
        <v-col class="text-center">
          <div class="text-h3 mt-5 primary--text">
            <b>שאלונים דיגיטליים וממותגים ללקוחות חדשים</b>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          xs="12"
          md="8"
          lg="6"
          offset-lg="3"
          offset-md="2"
          class="text-center">
          <div class="text-subtitle-1 black--text">
            במסגרת השירות ניתן לעשות שימוש במודול 'הכר את הלקוח' - יישום ייעודי לניהול הליך זיהוי והכרת הלקוח
            לפי חוק איסור הלבנת הון, התש"ס-2000. במסגרת ממשק אינטרנטי מתקדם,
            תוכלו לשלוח להפיק בקלות טפסי הכר את הלקוח, לנהל מעקב אחר השאלונים שנשלחו,
           לבצע הערכת סיכון, לחפש במרשם הגורמים שמעורבים בטרור, ועוד!
           </div>
           
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center">
          <br/>

          <LazyYoutube src="https://www.youtube.com/watch?v=On7RkrkDgUQ" />
          <br/>


          <!-- <v-carousel
            height="50vh"
            cycle
            hide-delimiter-background
            show-arrows-on-hover
          >
            <v-carousel-item
              v-for="(item, i) in items"
              :key="i"
              :src="item.src"
              contain
            ></v-carousel-item>
          </v-carousel> -->
        </v-col>
      </v-row>
    </v-container>
    <!-- <v-container pb-5 mt-5 fluid style="background: #1847e8; min-height:400px">
      <v-row>
        <v-col xs="12" md="6" lg="6" offset-lg="3" offset-md="3" align="center">
          <div class="text-h4 mt-5 mb-5 white--text">
            <b>הכר את הלקוח - סרטון הדרכה</b>
          </div>
          <LazyYoutube src="https://www.youtube.com/watch?v=On7RkrkDgUQ" />
        </v-col>
      </v-row>
    </v-container> -->

    <!-- Lawyer dashboard -->
    <v-container pb-10 pt-10 mt-5 fluid style="background: #1847e8; min-height:400px" >
  <v-row justify="center" align="center">
    <v-col xs="12" sm="10" md="8" lg="6">
      <v-row>
        <v-col xs="12" md="12" lg="4" class="text-right">
          <div class="text-h4 mt-5 white--text">
            <b>יתרונות מרכזיים בשימוש במודול השאלונים:</b>
          </div>
        </v-col>
        <v-col xs="12" md="12" lg="8">
          <div class="text-subtitle-1">
            <v-list-item
              two-line
              v-for="(item, index) in second_page_list"
              :key="index"
              light
            >
              <v-list-item-content>
                <v-list-item-title class="white--text">
                  <v-icon color="white">
                    mdi-check-decagram
                  </v-icon>
                  {{ item }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</v-container>




    <!-- List -->
    <v-container pb-5 pt-5 fluid style="min-height:300px">
  <v-row justify="center" style="height: 100%;">
    <v-col xs="12" md="8" lg="6" class="text-center">
      <div class="text-h3 mt-5">
        <b>אין למה לחכות!</b><br/>   

      </div>
      <div class="text-h4 mt-5">
           התחילו ב-30 ניסיון ללא עלות או התחייבות
        <br/><br/>

      </div>


      <v-btn x-large color="#1847e8" style="border-color: #1847e8; color: white; font-size: 24px;" to="/Register"> כניסה למערכת</v-btn>
    </v-col>
  </v-row>
</v-container>


    <!-- Video -->
  </LawyerLayout>
</template>

<script>
// @ is an alias to /src
import LawyerLayout from "@/components/LawyerLayout.vue";
import { LazyYoutube } from "vue-lazytube";

export default {
  name: "Home",
  data() {
    return {
      second_page_list: [
        "שאלונים אינטראקטיביים למילוי על-ידי לקוחות לצורך זיהוי והכרת הלקוח",
        "בדיקה אוטומטית של לקוחות מול מרשם הגורמים החשודים בטרור",
        "ממשק Web אינטראקטיבי עם מעקב אחרי כל הלקוחות שמילאו טפסים ",
        "שליחת השאלונים ללקוחות באמצעות מייל, WhatsApp או SMS",
        "מיתוג השאלונים עם הלוגו והעיצוב המשרדי (עלות הקמה חד-פעמית)",
        "אפשרות ליצירת שאלונים ומסמכים ייעודיים אעבור המשרד או הארגון"
      ],
      items: [
        {
          src: require("../assets/img/carousel/Mockup_01.png")
        },
        {
          src: require("../assets/img/carousel/Mockup_02.png")
        },
        {
          src: require("../assets/img/carousel/Mockup_03.png")
        },
        {
          src: require("../assets/img/carousel/Mockup_04.png")
        }
      ]
    };
  },
  components: {
    LawyerLayout,
    LazyYoutube
  }
};
</script>

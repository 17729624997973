<template>
    <LawyerLayout>
    <Header icon="file-document" text="התבניות שלי"></Header>
    <v-container style="max-width:1200px;">
           
      <v-row class="align-center">
  <v-col cols="12" sm="6">
    <v-file-input
      v-model="selectedFiles"
      accept=".docx"
      multiple
      label="בחר קבצים"
      outlined
      class="pt-8"
    ></v-file-input>
  </v-col>
  <v-col cols="12" sm="6" class="d-flex align-center justify-end">
    <v-btn
      v-if="selectedFiles.length"
      type="submit"
      :loading="loading"
      color="primary"
      @click="uploadTemplates"
      class="ml-auto"
      block
    >
      <v-icon class="mx-2" right>mdi-cloud-upload</v-icon>
      העלאת קבצים
    </v-btn>
  </v-col>
</v-row>
    <v-row v-if="successMessage">
      <v-col cols="12">
        <v-alert type="success" outlined>{{ successMessage }}</v-alert>
      </v-col>
    </v-row>

    <!-- Error message -->
    <v-row v-if="errorMessage">
      <v-col cols="12">
        <v-alert type="error" outlined>{{ errorMessage }}</v-alert>
      </v-col>
    </v-row>

    <v-card class="py-3 px-5 mb-4">
          <v-card-title>
              <b>התבניות שלי</b>
            </v-card-title>
            <v-divider />
            <v-card-text>
              <slot></slot>
            </v-card-text>

      <v-row class="child-flex">
        <v-col cols="12" md="6" lg="4" v-for="template in templates" :key="template.id">
          <v-card outlined class="mb-4">
            <v-card-title>
              <span>{{ template.name }}</span>
            </v-card-title>
            <v-card-subtitle>
              <span>{{ format_date(template.uploaded_at) }}</span>
            </v-card-subtitle>
            <v-card-actions>
              <v-btn text color="primary" @click="downloadTemplate(template.id)">
                הורדה
              </v-btn>
              <v-btn text color="error" @click="deleteTemplate(template.id)">
                מחיקה
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card>


    </v-container>
    </LawyerLayout>
</template>


<script>
// @ is an alias to /src
import moment from "moment";
//import LawyerProfilePageLayout from "@/components/LawyerProfilePageLayout.vue";
import ApiService from "@/services/ApiService.js";
import LawyerLayout from "@/components/LawyerLayout.vue";
import Header from "../components/Header.vue";

export default {
  name: "Lawfirm-Templates",
  data() {
    return {
      valid: false,
      loading: false,
      templates: [],
      snackbarShow: false,
      selectedFiles: [],
      successMessage: '', // Initialize as empty string
      errorMessage: '', // Initialize as empty string
    };
  },
  components: {
    //LawyerProfilePageLayout,
    LawyerLayout,
    Header
  },
  created() {
    ApiService.getTemplates()
      .then(response => {
        console.log(response)
        this.templates = response.data;
      })
      .catch(error => {
        console.log("Error fetching templates:", error);
      });
  },
  methods: 
  {
    uploadTemplates() {
      if (this.selectedFiles.length > 0) {
        const formDataArray = [];
      

      for (let i = 0; i < this.selectedFiles.length; i++) {
        const file = this.selectedFiles[i];
        const formData = new FormData();
        formData.append("file", file); // Use "file" as the key to match the server-side code
        console.log(file.name);
        formDataArray.push(formData);
      }

      this.loading = true;
      ApiService.uploadTemplates(formDataArray)
        .then(response => {
          if (response.status === 201) {
              
            this.successMessage = 'התבנית הועלתה בהצלחה';
            this.errorMessage = '';

            // this.loading = false;
            // this.snackbarShow = true;
            ApiService.getTemplates()
            .then(response => {
              console.log("templates fetched after upload")
              this.templates = response.data;
              //this.$set(this, 'templates', response.data);
              this.loading = false;
              //this.snackbarShow = true;
            })
            .catch(error => {
              console.log("Error fetching templates after upload:", error);
              this.loading = false;
            });

          }
        })
        .catch(err => {
          this.loading = false;
          if (err.response.status === 400) {
            const errorData = err.response.data;
            if (Array.isArray(errorData) && errorData.length > 0 && 'non_field_errors' in errorData[0]) {
              const uniqueError = errorData[0].non_field_errors[0];
              if (uniqueError.includes('unique set')) {
                this.errorMessage = 'כבר קיימת תבנית בשם הזה';
                this.successMessage = '';
                return;
              }
            }
            this.errorMessage = errorData;
            this.successMessage = '';
                  }
        });

      // Reset the file input value after uploading
      this.selectedFiles = [];
      this.loading = false;
      } 
      else {
      // No files selected, handle the error condition if needed
      console.log('No files selected');
      }

      this.successMessage = '';
      this.errorMessage = '';
    },
    downloadTemplate(templateId) {
      ApiService.getDownloadUrl(templateId)
      .then(response => {
        const downloadUrl = response;

      // Open a new window or tab with the download link
      window.open(downloadUrl, '_blank');
        })
      .catch(error => {
        console.log("Error retrieving download URL:", error);
      });
    },
    deleteTemplate(templateId) {
    console.log(templateId);
    ApiService.deleteTemplate(templateId)
      .then(() => {    
        // Remove the deleted template from the current list
        this.templates = this.templates.filter(template => template.id !== templateId);

        // Refresh the template list by fetching the updated list from the API
        ApiService.getTemplates()
          .then(response => {
            this.templates = response.data;

            console.log("Template deleted successfully.");
          })
          .catch(error => {
            console.log("Error fetching templates after deletion:", error);
          });
      })
      .catch(error => {
        console.log("Error deleting template:", error);
      });
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
    }}
  },
};
</script>

<template>
  <LawyerLayout>
    <Header icon="account" text="החשבון שלי"></Header>
    <v-container fluid class="px-0 py-0">
      <v-row class="child-flex">
        <v-toolbar dense flat class="grey">
          <v-spacer></v-spacer>
          <LawyerProfileNavDrawer />
          <v-spacer></v-spacer>
        </v-toolbar>
      </v-row>
    </v-container>
    <v-container class="px-0 mt-5">
      <!-- <DashboardStats /> -->
    </v-container>
    <v-container style="max-width:1200px;">
      <v-row>
        <v-col>
          <v-card class="py-3 px-5">
            <v-card-title>
              <b>{{ title }}</b>
            </v-card-title>
            <v-divider />
            <v-card-text>
              <slot></slot>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </LawyerLayout>
</template>

<script>
// @ is an alias to /src
import LawyerLayout from "@/components/LawyerLayout.vue";
import LawyerProfileNavDrawer from "@/components/LawyerProfileNavDrawer.vue";
//import DashboardStats from "./DashboardStats.vue";
import Header from "@/components/Header.vue";

export default {
  props: ["title"],
  name: "Lawyer-Profile-Page-Layout",
  components: {
    LawyerLayout,
    LawyerProfileNavDrawer,
    Header
  }
};
</script>

<template>
    <LawyerLayout>
      <v-container fluid class="pt-0">
        <v-row calss="mt-0" style="background: #efefef">
          <v-col class="text-center">
            <div class="text-h5 mt-0">
              <v-btn icon to="/Dashboard">
                <v-icon medium color="primary"
                  >mdi-arrow-right-thick</v-icon
                > </v-btn
              >|
              <b>הוספת שאלון חדש</b>
              |
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    medium
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    class="mx-2"
                    @click="copyText(url)"
                  >
                    mdi-content-copy
                  </v-icon>
                </template>
                <span>העתק את הלינק לשאלון</span>
              </v-tooltip>
            </div>
            <div class="text mt-0">
              הלקוח: <b>{{ $route.query.client_name }}</b>
            </div>
          </v-col>
        </v-row>
        <div
          id="pf"
          data-prefill-inherit="1"
          data-scroll-offset="152"
          data-paperform-id="create-new-form"
          class="h-100"
          spinner="1"
        ></div>
      </v-container>
    </LawyerLayout>
  </template>
  
  <script>
  import LawyerLayout from "@/components/LawyerLayout.vue";
  
  export default {
    name: "Dashboard",
    components: {
      LawyerLayout
    },
    data() {
      return {
        url: location.href,
        questionnaire: "create-new-form"
      };
    },
    mounted() {
      // Mount paperform in background
      var script = document.createElement("script");
      script.src = "https://paperform.co/__embed.min.js";
      document.body.appendChild(script);
    },
    methods: {
      copyText(text) {
        navigator.clipboard.writeText(text);
      }
    }
  };
  </script>
  
  <style lang="scss" scoped></style>

<!-- <template>
    <LawyerLayout>
    <v-card-text>
        <v-container>
            <v-row>
                <v-col v-for="question in questions" :key="question.key" cols="12">
                    <v-card v-if="question.type !== 'choices'">
                        <v-card-text>
                            {{ question.title }}
                        </v-card-text>
                        <v-card-text>
                            <template v-if="question.type === 'text'">
                                <v-text-field v-model="question.title" label="שאלה" outlined></v-text-field>
                                <v-text-field v-model="question.description" label="תיאור (לא חובה)"
                                    outlined></v-text-field>
                            </template>

                            <template v-else-if="question.type === 'choices'">
                                <v-select v-model="question.answer" :label="question.title" :items="question.options"
                                    outlined></v-select>
                            </template>
                        </v-card-text>
                        <v-card-text>
                            <v-row align="center">
                                <v-col cols="auto">
                                    שדה חובה:
                                </v-col>
                                <v-col cols="auto">
                                    <v-switch v-model="question.required"></v-switch>
                                </v-col>
                            </v-row>
                        </v-card-text>

                        <v-card-text>
                            סוג שדה: {{ question.type }}
                        </v-card-text>
                        <v-card-text>
                            מפתח: {{ question.custom_key }}
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col v-if="questions && questions.length === 0" cols="12">
                    No fields available.
                </v-col>
            </v-row>
        </v-container>
    </v-card-text>
</LawyerLayout>

</template>

<script>

//import { useRouter } from 'vue-router';
import ApiService from "@/services/ApiService.js";
import LawyerLayout from "@/components/LawyerLayout.vue";



export default {
    props: {
    questionnaireName: String,
    },
    data() {
        return {
            questionnaire_name: '',
            questions: [],
        };
    },
    components: {
    //LawyerProfilePageLayout,
    LawyerLayout,
  },

    mounted() {
    //const router = useRouter();
    const questionnaireName = this.$route.params.questionnaireName;
    this.questionnaire_name = questionnaireName;
    this.getTemplateFields();
  },
    methods: {
        getTemplateFields() {
            console.log("getTemplateFields");
            console.log(this.questionnaire_name);
            //get the template fields of the docx template from the backend
            ApiService.getTemplateFields(this.questionnaire_name)
                .then((response) => {
                    this.questions = response.data;
                    console.log(response.data);
                })
                .catch((error) => {
                    console.log("There were errors: " + error);
                });
        },
    },
};
</script> -->
<template>
  <div>
    <v-dialog v-model="dialog" max-width="500px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn block color="primary" elevation="2" outlined x-large class="mb-2" v-bind="attrs" v-on="on">
          <v-icon class="ml-2">mdi-plus-thick</v-icon>
          צור הפניה לשאלון
        </v-btn>
      </template>
      <v-form ref="newReferenceForm">
        <v-card>
          <v-card-title class="justify-center">
            <span class="headline"><b>יצירת הפניה חדשה למילוי שאלון</b></span>
          </v-card-title>

          <v-card-text class="mb-0 custom-card-text">
            <v-col cols="12">
              <v-select v-model="editedItem.questionnaire_name" outlined persistent-hint solo dense label="טופס"
                hint="בחר את הטופס שאתה מעוניין שהלקוח ימלא" :items="formOptions" :rules="formOptionsRules" />

              <v-radio-group v-model="sendOption" row>
                <v-radio label="שליחה לנמען בודד" value="individual"></v-radio>
                <v-radio label="שליחה לקבוצת נמענים" value="group"></v-radio>
              </v-radio-group>

            </v-col>
          </v-card-text>


          <div v-if="sendOption === 'individual'">
            <v-card-text class="mt-0 custom-card-text">
              <div class="text-caption">
                עם יצירת הפניה למילוי שאלון תקבל לינק שתוכל לשתף עם מי שאמורים למלא את הטופס.
                הלינק מאפשר למלא את השאלון מכל מכשיר - ניתן לעקוב על סטטוס ההתקדמות בטבלת ההפניות.
              </div>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="editedItem.client_name" required outlined dense label="ממלא הטופס (הנמען)"
                      hint="יש להזין את השם שם ממלא הטופס (הנמען). כך תוכלו לעקוב בהמשך על ההתקדמות." persistent-hint
                      :rules="clientNameRules"></v-text-field>
                    <v-switch v-model="editedItem.send_email" label="לשלוח לנמען קישור לטופס במייל?" color="primary"
                      hide-details></v-switch>
                    <v-text-field v-if="editedItem.send_email" v-model="editedItem.client_email" outlined dense required
                      label="כתובת האימייל של הנמען" class="mt-2" :rules="clientEmailRules"></v-text-field>

                    <div v-if="showButton">
                      <label>אנא בחר/י עבור מי השאלון:</label>
                      <v-radio-group v-model="editedItem.parameters" row>
                        <v-radio label="יחיד" value="private"></v-radio>
                        <v-radio label="תאגיד" value="corporate"></v-radio>
                        <v-radio label="ללא בחירה" value=null></v-radio>
                      </v-radio-group>
                    </div>



                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </div>

          <!-- CSV Upload Input for Group Sending -->
          <v-card-text v-else-if="sendOption === 'group'" class="mt-0 custom-card-text">
            <v-col cols="12">

              <v-file-input label="בחירת גיליון נתונים" accept=".csv" v-model="csvFile"></v-file-input>
              <p>
                נא להשתמש בגיליון נתונים שמתאים למבנה הנתונים הנדרש: שם לקוח, כתובת אימייל וסוג לקוח (יחיד או תאגיד).
                כל ערך צריך להיות ממוקם בעמודה נפרדת, כך שכל שורה בגיליון מייצגת לקוח אחר
                <br>
                <a href="https://cligal.com/wp-content/uploads/kyc-sample.csv" target="_blank" download>
                  לחץ כאן להורדת דוגמא לקובץ CSV
                </a>
              </p>
            </v-col>

          </v-card-text>



          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close">
              בטל
            </v-btn>
            <v-btn color="primary" @click="save">
              שמור
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <!-- Snack bar notification bar -->
    <v-snackbar id="simple-snack-bar" width="30vw" v-model="snackbarShow" outlined color="primary"
      transition="fade-transition" style="margin: 0 auto; top: 50%; transform: translateY(-50%);">
      <v-row>
        <v-col cols="12" class="d-flex justify-center align-center">
          <v-icon color="primary">
            mdi-check-circle
          </v-icon>
          נוצרה הפניה חדשה לשאלון!
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="5" class="d-flex justify-center align-center">
          <v-btn color="primary" outlined small @click="openLink(snackbarLink)">
            <v-icon class="ml-2" small>
              mdi-open-in-new
            </v-icon>
            פתח קישור
          </v-btn>
        </v-col>
        <v-col cols="5" class="d-flex justify-center align-center">
          <v-btn color="primary" outlined small @click="copyTextToClipboard(snackbarLink)">
            <v-icon class="ml-2" small>
              mdi-content-copy
            </v-icon>
            העתק קישור
          </v-btn>
        </v-col>
        <v-col cols="2" class="d-flex justify-center align-center">
          <v-btn color="primary" icon>
            <v-icon class="ml-2" @click="snackbarShow = false">
              mdi-close-circle-outline
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-snackbar>
    <!-- New Snackbar for CSV upload messages -->
    <v-snackbar id="csvSnackBar" v-model="csvUploadSnackbarShow" :color="csvUploadSnackbarColor" outlined
      transition="fade-transition" style="margin: 0 auto; top: 60%; transform: translateY(-50%);">
      <v-row>
        <v-col cols="10" class="d-flex justify-center align-center">
          <v-icon :color="csvUploadSnackbarColor === 'success' ? 'green' : 'red'">
            mdi-information
          </v-icon>
          {{ csvUploadSnackbarText }}
        </v-col>
        <v-col cols="2" class="d-flex justify-end align-center">
          <v-btn icon @click="csvUploadSnackbarShow = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-snackbar>
  </div>
</template>
<script>
import ApiService from "@/services/ApiService.js";
export default {
  data: () => ({
    snackbarShow: false,
    csvUploadSnackbarShow: false,
    csvUploadSnackbarColor: 'info', // Default color
    csvUploadSnackbarText: '',
    editedItem: {
      questionnaire_name: "",
      client_name: "",
      client_email: "",
      client_tel: "",
      send_email: true,
      parameters: "",
    },
    defaultItem: {
      questionnaire_name: "",
      client_name: "",
      client_email: "",
      client_tel: "",
      send_email: true,
    },
    dialog: false,
    formOptions: [],
    showButton: false,
    clientNameRules: [(v) => !!v || "נא ציין שם לקוח"],
    clientEmailRules: [
      (v) => !!v || "אנא הזן כתובת מייל",
      (v) =>
        !v ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "כתובת מייל לא חוקית",
    ],
    formOptionsRules: [(v) => !!v || "בחר טופס"],
    // ... other data properties ...
    sendOption: 'individual',  // default to individual
    csvFile: null,
    // New properties for the CSV upload snackbar
  }),
  watch: {
    dialog(val) {
      val || this.close();
    },
    'editedItem.questionnaire_name': function (newVal) {
      if (newVal.includes('knowyourclient')) {
        // Set a boolean flag to true to show the v-button
        console.log(newVal);
        this.showButton = true;
      } else {
        // Set the flag to false to hide the v-button
        this.showButton = false;
        console.log(newVal);

      }
    }
    // dialogDelete(val) {
    //   val || this.closeDelete();
    // }
  },
  created() {
    ApiService.getQuestionnaire()
      .then((response) => {
        this.questionnaireLoading = false;
        var questionnaires = [];
        response.data.forEach((element) => {
          questionnaires.push({
            text: element.display_name_heb,
            value: element.short_name,
          });
        });
        this.formOptions = questionnaires;
      })
      .catch((error) => {
        console.log("There was errors: " + error);
      });
  },
  methods: {
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },
    validateForm() {
      return this.$refs.newReferenceForm.validate();
    },
    async save() {
      if (this.sendOption === 'group' && this.csvFile) {
        await this.handleCSVUpload(); // If you're handling group sending and a file is uploaded, then process CSV
      } else if (this.sendOption === 'individual') {
        if (this.validateForm()) {
          var newItemData = {
            questionnaire_name: this.editedItem["questionnaire_name"],
            client_name: this.editedItem["client_name"],
          };
          if (this.editedItem["send_email"] && this.editedItem["client_email"]) {
            newItemData["client_email"] = this.editedItem["client_email"];
          }
          if (this.editedItem["parameters"] == "private")
            newItemData["parameters"] = "CompanyOrClient=יחיד"
          if (this.editedItem["parameters"] == "corporate")
            newItemData["parameters"] = "CompanyOrClient=תאגיד"
          ApiService.createNewReference(newItemData)
            .then((response) => {
              this.snackbarLink = response.data["link"];
              this.snackbarText = response.data["client_name"];
              this.snackbarShow = true;
            })
            .catch((error) => {
              console.log("There was errors: " + error);
            });
          this.close();
        }
      }
    },
    copyLink(item) {
      var textArea = document.createElement("textarea");
      textArea.value = item;
      // Avoid scrolling to bottom
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";

      document.body.appendChild(textArea);
      textArea.focus();

      /* Select the text field */
      textArea.select();
      textArea.setSelectionRange(0, 99999); /*For mobile devices*/
      /* Copy the text inside the text field */
      document.execCommand("copy");
    },
    fallbackCopyTextToClipboard(text) {
      var textArea = document.createElement("textarea");
      textArea.value = text;

      // Avoid scrolling to bottom
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        var successful = document.execCommand("copy");
        var msg = successful ? "successful" : "unsuccessful";
        console.log("Fallback: Copying text command was " + msg);
      } catch (err) {
        console.error("Fallback: Oops, unable to copy", err);
      }

      document.body.removeChild(textArea);
    },
    copyTextToClipboard(text) {
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(text);
        return;
      }
      navigator.clipboard.writeText(text).then(
        function () {
          console.log("Async: Copying to clipboard was successful!");
        },
        function (err) {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },
    openLink(snackbarLink) {
      window.open(snackbarLink);
    },
    handleCSVUpload() {
      console.log("handleCSVUpload called");
      if (!this.csvFile) {
        console.error("No file selected!");
        return;
      }

      const reader = new FileReader();

      reader.onload = async e => {
        try {
          console.log("File content:", e.target.result);
          const text = e.target.result;
          const rows = text.split('\n').slice(1);; // Splitting rows

          // Initialize a success counter
          let successCount = 0;


          // Map through each row
          const clients = rows.map((row, index) => {
          // Check for and handle BOM (Byte Order Mark) if present
          if (index === 0 && row.charCodeAt(0) === 0xFEFF) {
            row = row.slice(1);
          }
          
          // Splitting row into columns and trimming values to handle irregular characters
          const columns = row.split(',').map(column => column.trim());
          
          // Check for rows with less than three columns
          if (columns.length < 3) {
            console.error(`Row ${index + 1} has less than three columns: ${row}`);
            return null;
          }
          
          // Check for empty columns in the first three positions
          if (!columns[0] || !columns[1] || !columns[2]) {
            console.error(`Row ${index + 1} has one or more empty columns in the first three positions: ${row}`);
            return null;
          }
          
          // Return an object for valid rows (you might want to adjust this to fit your actual data structure)
          return {
            name: columns[0],
            email: columns[1],
            type: columns[2]
          };
        }).filter(client => client !== null); // Filter out null values representing invalid rows

          console.log("Clients:", clients);
          // Handle clients data here - using for-of loop to await the save function
          for (const client of clients) {
            if (!client.name || !client.email || !client.type) {
              console.error(`Invalid client data: ${JSON.stringify(client)}`);
              continue; // Skip this iteration and move to the next client
            }

            let newItemData = {
              questionnaire_name: this.editedItem["questionnaire_name"],
              client_name: client.name,
              client_email: client.email, // Since send_email is always true and all have email
              parameters: client.type === "private" || client.type === "יחיד" || client.type === "individual" ? "CompanyOrClient=יחיד" : client.type === "corporate" || client.type === "תאגיד" ? "CompanyOrClient=תאגיד" : client.parameters
            };

            console.log(newItemData);

            ApiService.createNewReference(newItemData)
              .then(response => {
                successCount++;
                console.log(`New reference created for ${client.client_name}:`, response.data);
                // Optionally update UI based on success
              })
              .catch(error => {
                console.error(`Error creating reference for ${client.client_name}:`, error);
                // Update UI to show error
                this.csvUploadSnackbarText = `Failed to create reference for ${client.name}`;
                this.csvUploadSnackbarColor = 'error'; 
                this.csvUploadSnackbarShow  = true;

              });

          }
          // After all clients have been processed, update the UI to show the count of successful calls
          // This part assumes you have a method or way to update the UI with the success count.
          // You might need to adjust this to fit how your application handles asynchronous updates
          this.$nextTick(() => {
            if (successCount > 0) {
              this.csvUploadSnackbarText = `${successCount} clients successfully processed.`;
              this.csvUploadSnackbarColor = 'success';
              this.csvUploadSnackbarShow = true;
            }
          });
        } catch (error) {
          console.error("Error reading or processing the CSV file:", error);
          // Handle the error in a way suitable for your application
        }
      };


      reader.readAsText(this.csvFile);
    },
  },
};
</script>
<style lang="scss" scoped>
simple-snack-bar span {
  margin: auto;
  text-align: center;
}

.custom-card-text {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.submissions,"loading":_vm.DataIsLoading,"sort-by":"created_at","sort-desc":true},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"primary"}},[_c('v-toolbar-title',{staticClass:"white--text"},[_c('b',[_vm._v("תשובות שהתקבלו")])]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('span',{staticClass:"text-h8 white--text"},[_vm._v(_vm._s(_vm.submissionsCount)+" שאלונים שמולאו על ידי הלקוחות")]),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" למחוק את השורה?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("בטל")]),_c('v-btn',{attrs:{"color":"red","outlined":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("מחק")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.format_date(item.created_at))+" ")]}},{key:"item.is_done",fn:function(ref){
var item = ref.item;
return [(item.is_done)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close")])]}},{key:"item.questionnaire",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"outlined":""}},[_vm._v(" "+_vm._s(item.questionnaire)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex"}},[(item.questionnaire === 'טופס הכר את הלקוח')?_c('div',[(item.riskassessment_pdf_link)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pdf-icon",staticStyle:{"margin-left":"5px"},attrs:{"href":item.riskassessment_pdf_link,"target":"_blank","color":"primary","icon":""}},'v-btn',attrs,false),on),[_c('v-img',{staticClass:"d-inline-block align-top",staticStyle:{"width":"20px","aspect-ratio":"auto","":":v-deep img.pdf-icon { max-height: 20px"},attrs:{"src":require("@/assets/icons/pdf-icon.png")}})],1)]}}],null,true)},[_c('span',[_vm._v("צפה במסמך הערכת הסיכון")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"to":'/qs-lawyer/kyc-risk-assessment?client_name=' +
                item.sender_name +
                '&pubsub=' +
                item.pubsub +
                '&lawyerFullName=' +
                _vm.lawyer_fullname +
                '&lawyer_email=' +
                _vm.username,"icon":""}},'v-btn',attrs,false),on),[(item.riskassessment_pdf_link)?_c('v-icon',[_vm._v("mdi-refresh")]):_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-file-document-edit")])],1)]}}],null,true)},[(item.riskassessment_pdf_link)?_c('span',[_vm._v("התחל הליך חדש של הערכת סיכון")]):_c('span',[_vm._v("הליך הערכת סיכון")])])],1):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mx-2",on:{"click":function($event){return _vm.deleteItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("מחק")])])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import Login from "@/views/Login.vue";
import Register from "@/views/Register.vue";
import Changepassword from "@/views/Changepassword.vue";
import ResetpasswordConfirm from "@/views/ResetpasswordConfirm.vue";
import Resetpassword from "@/views/Resetpassword.vue";
import LawyerProfile from "@/views/LawyerProfile.vue";
import FirmTemplates from "@/views/FirmTemplates.vue";
import Dashboard from "@/views/Dashboard.vue";
import TerrorListSearch from "@/views/TerrorListSearch.vue";
import Questionnaire from "@/views/Questionnaire.vue";
import LawyerQuestionnaire from "@/views/LawyerQuestionnaire.vue";
import ParagraphsList from "@/views/ParagraphsList.vue";
import EditExistingQuestionnaire from "@/views/EditExistingQuestionnaire.vue";
import addNewQuestionnaire from "@/views/addNewQuestionnaire.vue";
import CaptureToken from "@/views/CaptureToken.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/qs/:firmname",
    name: "Questionnaire",
    component: Questionnaire,
    meta: { refCheck: true }
  },
  {
    path: "/qs-lawyer/:questionnaire",
    name: "LawyerQuestionnaire",
    component: LawyerQuestionnaire
  },
  {
    path: "/Login",
    name: "Login",
    component: Login,
    meta: { preventAuth: true }
  },
  {
    path: "/Register",
    name: "Register",
    component: Register,
    meta: { preventAuth: true }
  },
  {
    path: "/Reset-password/confirm/",
    name: "Reset-password-confirm",
    component: ResetpasswordConfirm,
    meta: { preventAuth: true }
  },
  {
    path: "/Reset-password",
    name: "Reset-password",
    component: Resetpassword,
    meta: { preventAuth: true }
  },
  {
    path: "/Templates",
    name: "Templates",
    component: FirmTemplates,
    meta: { requiresAuth: true }
  },
  {
    path: "/Paragraphs",
    name: "Paragraphs",
    component: ParagraphsList,
    meta: { requiresAuth: true }
  },
  {
    path: "/Profile",
    name: "Profile",
    component: LawyerProfile,
    meta: { requiresAuth: true }
  },
  {
    path: "/Change-password",
    name: "Change-password",
    component: Changepassword,
    meta: { requiresAuth: true }
  },
  {
    path: "/Dashboard",
    name: "Dashboard",
    component: Dashboard,
    //Define the pages which need auth
    meta: { requiresAuth: true }
  },
  {
    path: "/List",
    name: "List",
    component: TerrorListSearch,
    //Define the pages which need auth
    meta: { requiresAuth: true }
  },
  {
    path: "/EditQuestionnaire/:questionnaireName",
    name: "EditQuestionnaire",
    component: EditExistingQuestionnaire,
    //Define the pages which need auth
    meta: { requiresAuth: true }
  },
  {
    path: "/AddQuestionnaire/:questionnaireName",
    name: "AddQuestionnaire",
    component: addNewQuestionnaire,
    //Define the pages which need auth
    meta: { requiresAuth: true }
  },
  {
    path: '/capture-token',
    name: 'CaptureToken',
    component: CaptureToken
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

// ENFORCE AUTH ON EVERY RELEVANT LINK
// if user tries to access pages for auth users -> redirect to (/)
router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("t");
  if (to.matched.some(recored => recored.meta.requiresAuth) && !loggedIn) {
    next({ name: "Login" });
  } else if (to.matched.some(recored => recored.meta.preventAuth) && loggedIn) {
    next({ name: "Dashboard" });
  } else {
    next();
  }
});

export default router;

<template>
  <LawyerLayout>
    <v-container fill-height>
      <v-row align="center" justify="center">
        <v-col xs="10" md="6" xl="4">
          <v-card elevation="3" outlined light class="mt-auto">
            <v-card-title class="primary white--text py-2">
              <v-layout justify-center>
                <v-icon dark size="20px" class="ml-3"> mdi-lock</v-icon>
                <h4>איפוס סיסמה</h4>
              </v-layout>
            </v-card-title>
            <v-divider />
            <v-card-text align="center">
              <v-col v-if="tokenExpired" cols="10">
                <v-alert outlined type="warning">
                  לינק איפוס הסיסמה אינו תקף עוד. הלינק לאיפוס הסיסמה תקף לזמן
                  קצר לאחר יצירתו.<br />
                  <v-btn class="mt-2" outlined to="/Reset-password"
                    >צור חדש</v-btn
                  >
                </v-alert>
              </v-col>
              <v-col v-if="!tokenExpired" cols="10">
                <v-form
                  v-if="!msg"
                  v-model="valid"
                  @submit.prevent="changePassword"
                >
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="newpassword"
                        label="סיסמה חדשה"
                        :type="shownewpassword ? 'text' : 'password'"
                        outlined
                        clearable
                        required
                        :rules="passwordRules"
                        :append-icon="
                          shownewpassword ? 'mdi-eye' : 'mdi-eye-off'
                        "
                        @click:append="shownewpassword = !shownewpassword"
                      ></v-text-field>

                      <div class="text-subtitle-2 mb-5">
                        הסיסמה אינה יכולה להיות דומה לשם משתמש שלך.<br />
                        הסיסמה צריכה להיות לפחות באורך של 8 תווים.<br />
                        הסיסמה אינה יכולה להיות אחת מהסיסמות הנפוצות ביותר.<br />
                        הסיסמה אינה יכולה להכיל רק מספרים בלבד.<br />
                      </div>
                      <v-text-field
                        v-model="confirmPassword"
                        label="ווידוא סיסמה חדשה"
                        :type="showconfirmPassword ? 'text' : 'password'"
                        outlined
                        clearable
                        required
                        :rules="confirmPasswordRules"
                        :append-icon="
                          showconfirmPassword ? 'mdi-eye' : 'mdi-eye-off'
                        "
                        @click:append="
                          showconfirmPassword = !showconfirmPassword
                        "
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col align="center">
                      <v-btn
                        type="submit"
                        :loading="loading"
                        :disabled="!valid"
                        color="success"
                        @click="loader = 'loading'"
                      >
                        <v-icon class="mx-2" right>mdi-lock</v-icon>
                        החלף סיסמה
                      </v-btn>
                      <v-alert
                        class="mt-5"
                        v-for="(item, index) in error"
                        :key="index"
                        dense
                        outlined
                        type="warning"
                      >
                        <span v-for="i in item" :key="i">
                          {{ i }}
                        </span>
                      </v-alert>
                    </v-col>
                  </v-row>
                </v-form>
                <v-alert v-if="msg" outlined type="success">
                  {{ msg }}<br />
                  <v-btn class="mt-2" to="/Login" color="success">
                    <v-icon right class="ml-2">mdi-lock</v-icon>
                    היכנס למערכת</v-btn
                  >
                </v-alert>
              </v-col>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </LawyerLayout>
</template>

<script>
import LawyerLayout from "@/components/LawyerLayout.vue";
import ApiService from "@/services/ApiService.js";

export default {
  components: {
    LawyerLayout
  },
  data() {
    return {
      token: "",
      tokenExpired: false,
      valid: false,
      error: "",
      msg: "",
      loading: false,
      newpassword: "",
      confirmPassword: "",
      shownewpassword: false,
      showconfirmPassword: false,
      passwordRules: [
        value => !!value || "אנא  הזן סיסמה חדשה",
        value =>
          (value && value.length >= 8) ||
          "הסיסמה קצרה מדי (נדרש לפחות 8 תווים)",
        value => /(?!^\d+$)^.+$/.test(value) || "הסיסמה מכילה רק מספרים"
      ],
      confirmPasswordRules: [
        value => !!value || "אנא הזן שוב את הסיסמה החדשה",
        value => value === this.newpassword || "הסיסמאות שהזנת אינן זהות"
      ]
    };
  },
  methods: {
    changePassword() {
      this.loading = true;
      ApiService.resetPasswordConfirm(this.token, this.newpassword)
        .then(response => {
          console.log(response);
          this.loading = false;
          this.error = "";
          this.msg = "סיסמה שונתה בהצלחה";
        })
        .catch(err => {
          this.loading = false;
          this.msg = "";
          this.error = err.response.data;
        });
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // check before enter the route
      // if check failed, you can cancel this routing
      vm.token = vm.$route.query.token;
      ApiService.resetPasswordTokenCheckValid(vm.token)
        .then(response => {
          console.log(response);
          next();
        })
        .catch(error => {
          console.log("There was errors: " + error);
          vm.tokenExpired = true;
        });
    });
  }
};
</script>

<style lang="scss" scoped></style>

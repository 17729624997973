<template>
  <LawyerProfilePageLayout title="פרטים אישיים">
    <!-- <DashboardStats /> -->
    <div class="text-subtitle-1 mb-4">
      <b>שם משתמש</b>: {{ username }} | <b>משרד עו״ד</b>: {{ lawfirm.name }} |
      <b>סוג חשבון</b>: {{ subscription_type }}
    </div>
    <v-form v-model="valid" @submit.prevent="updateProfile">
      <v-row>
        <v-col>
          <v-text-field
            v-model="first_name"
            label="שם פרטי"
            outlined
            required
            hint="וודא ששמך כתוב בצורה נכונה. זה השם שיופיע ללקוחתיך בפניות אליהם"
            persistent-hint
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="last_name"
            label="שם משפחה"
            outlined
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="admin_email"
            label="דואר אלקטרוני של המנהל/ת"
            outlined
            required
            hint="יש למלא כתובת אליה יישלח מייל עדכון לאחר כל מילוי של שאלון"
            persistent-hint
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="admin_name"
            label="שם המנהל/ת"
            outlined
            required
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col align="center">
          <v-btn
            type="submit"
            :loading="loading"
            :disabled="!valid"
            color="primary"
            @click="loader = 'loading'"
          >
            <v-icon class="mx-2" right>mdi-lock</v-icon>
            עדכן פרטים
          </v-btn>
          <v-alert
            class="mt-5"
            v-for="(item, index) in error"
            :key="index"
            dense
            outlined
            type="warning"
          >
            <span v-for="i in item" :key="i">
              {{ i }}
            </span>
          </v-alert>
        </v-col>
      </v-row>
    </v-form>
    <!-- Snack bar notification bar -->
    <v-snackbar v-model="snackbarShow" timeout="2000" outlined color="primary">
      <v-icon small class="mr-2" color="primary">
        mdi-check-circle
      </v-icon>
      פרטי החשבון עודכנו בהצלחה
      <template v-slot:action="{ attrs }">
        <v-btn outlined text v-bind="attrs" @click="snackbarShow = false">
          סגור
        </v-btn>
      </template>
    </v-snackbar>
  </LawyerProfilePageLayout>
</template>

<script>
// @ is an alias to /src
import LawyerProfilePageLayout from "@/components/LawyerProfilePageLayout.vue";
import ApiService from "@/services/ApiService.js";

export default {
  name: "Lawyer-Profile",
  data() {
    return {
      valid: false,
      loading: false,
      username: "",
      first_name: "",
      last_name: "",
      admin_name:"",
      admin_email:"",
      error: "",
      lawfirm: {},
      snackbarShow: false
    };
  },
  components: {
    LawyerProfilePageLayout
  },
  computed: {
    subscription_type() {
      if (this.lawfirm.subscription_type === "paid") {
        return "מנוי";
      } else {
        return "נסיון";
      }
    }
  },
  created() {
    ApiService.getProfile()
      .then(response => {
        this.loading = false;
        var res = response.data;
        this.username = res["user"]["username"];
        this.first_name = res["first_name"];
        this.last_name = res["last_name"];
        this.lawfirm = res["lawfirm"];
        this.admin_email = res["admin_email"];
        this.admin_name = res["admin_name"];
      })
      .catch(error => {
        console.log("There was errors: " + error);
      });
  },
  methods: {
    updateProfile() {
      this.loading = true;
      ApiService.updateProfile(this.first_name, this.last_name, this.admin_email, this.admin_name)
        .then(response => {
          if (response.status === 200) {
            this.loading = false;
            this.snackbarShow = true;
          }
        })
        .catch(err => {
          this.loading = false;
          if (err.response.status === 400) {
            this.error = err.response.data;
          }
        });
    }
  }
};
</script>
